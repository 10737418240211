import GetAllCategoriesHook from '../Categories/GetAllCategoriesHook'
import GetAllDistrictsHook from '../Districts/GetAllDistrictsHook'
import GetAllGatesHook from '../Gates/GetAllGatesHook'
import GetAllPropertiesHook from '../PropertiesHook/GetAllPropertiesHook'
import Cookies from "js-cookie"
import { useTranslation } from 'react-i18next'

const AddUnitOptionsHook = () => {
  const [categoriesLoading,categories] = GetAllCategoriesHook() 
  const [districtLoading,districts] = GetAllDistrictsHook() 
  const  [propertiesLoading,properties] = GetAllPropertiesHook() 
  const [gatesLoading, gates] = GetAllGatesHook();
  const lang = Cookies.get("i18next")
  const [t] = useTranslation();
  const categoryList = [
    categoriesLoading === false
      ? categories
        ? categories?.map((item, i) => {
            return {
              id: `${item?.id}`,
              value: `${
                lang === "en"
                  ? item?.data_trans
                    ? item?.data_trans[1]?.title_en
                    : null
                  : item?.data_trans
                  ? item?.data_trans[0]?.title_ar
                  : null
              }`,
              label: `${
                lang === "en"
                  ? item?.data_trans
                    ? item?.data_trans[1]?.title_en
                    : null
                  : item?.data_trans
                  ? item?.data_trans[0]?.title_ar
                  : null
              }`,
            };
          })
        : null
      : null,
  ];
  const DistrictsOptions = [
    districtLoading === false?
    districts?.map((item)=>{  return {
      id: `${item?.id}`,
      value: `${
        lang === "en"
          ? item?.data_trans
            ? item?.data_trans[1]?.title_en
            : null
          : item?.data_trans
          ? item?.data_trans[0]?.title_ar
          : null
      }`,
      label: `${
        lang === "en"
          ? item?.data_trans
            ? item?.data_trans[1]?.title_en
            : null
          : item?.data_trans
          ? item?.data_trans[0]?.title_ar
          : null
      }`,
    };})
    :
    null
  ]
  const gatesOptions = [
    gatesLoading === false
      ? gates
        ? gates?.map((item) => {
            return {
              id: `${item?.id}`,
              value: `${
                lang === "en"
                  ? item?.data_trans
                    ? item?.data_trans[0]?.title_en
                    : null
                  : item?.data_trans
                  ? item?.data_trans[1]?.title_ar
                  : null
              }`,
              label: `${
                lang === "en"
                  ? item?.data_trans
                    ? item?.data_trans[0]?.title_en
                    : null
                  : item?.data_trans
                  ? item?.data_trans[1]?.title_ar
                  : null
              }`,
            };
          })
        : null
      : null,
  ];
  const typeList = [
    { value: "sale", label: t("listingPage.listingSidebar.sale") },
    { value: "rent", label: t("listingPage.listingSidebar.rent") },
  ];
  const payingList = [
    { value: "cash", label: "كاش",id:"1" },
    { value: "noCash", label: "تقسيط",id:"2" },
  ];
  const mainCategoryList = [
    { value: "سكنى", label: "سكنى",id:"1" },
    { value: "ادارى", label: "ادارى",id:"2" },
    { value: "تجارى", label: "تجارى",id:"3" },
  ];
  return [categoryList,typeList,DistrictsOptions,propertiesLoading,properties,gatesOptions,payingList,mainCategoryList,lang,t]
}

export default AddUnitOptionsHook
