import React from "react";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
// import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import { useTranslation } from "react-i18next";
import WantToSell from "../../../components/Home/WantToSell/WantToSell";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import ListContent from "../../../components/Listing/ListContent/ListContent";
import ScrollToTopBtn from "../../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Footer from "../../../components/GeneralSections/Footer/Footer";

const InstallmentUnitsPage = () => {
  const { t } = useTranslation();

  return (
    <div className="listing-page page">
      <CustomHelmet
        title={t("installment_page.breadcrumbTitle")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader
        installmentActive={"installmentActive"}
        installment={true}
      />
      {/* <BreadcrumbSection title={t("installment_page.breadcrumbTitle")} /> */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ListContent unitsType={"installment"} />
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default InstallmentUnitsPage;
