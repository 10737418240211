import React from "react";
import ContactUsSidebar from "../../components/ContactUs/ContactUsSidebar/ContactUsSidebar";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import WantToSell from "../../components/Home/WantToSell/WantToSell";
import Footer from "../../components/GeneralSections/Footer/Footer";
// import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OurInfoHook from "../../CustomHooks/OurSettings/OurInfo/OurInfoHook";
import ContactUsHook from "../../CustomHooks/OurSettings/ContactUs/ContactUsHook";
import BtnComponent from "../../components/Utility/BtnComponent/BtnComponent";

const ContactUsPage = () => {
  const [data] = OurInfoHook();
  const [
    ,
    name,
    phone,
    ,
    message,
    ,
    onChangeName,
    ,
    onChangePhone,
    ,
    onChangeMessage,
    ,
    onSubmit,
    loading,
    isPress,
    t,
  ] = ContactUsHook();

  return (
    <div className="contact-us-page page">
      <CustomHelmet
        title={t("contactUsPage.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader />
      {/* <BreadcrumbSection title={t("contactUsPage.title")} /> */}
      <section className="wrap__contact-form">
        <div className="container">
          <div className="row wrap-contact my-3">
            <div className="col-md-4 position-relative p-2">
              <ContactUsSidebar data={data} />
              <div className="box map p-3">
                <ul className="list-row d-flex justify-content-center">
                  <li className="list-row-item">
                    <a
                      href={data?.facebook || "facebook.com"}
                      className="btn-social facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li className="list-row-item">
                    <a
                      href={data?.twitter || "twitter.com"}
                      className="btn-social twitter"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li className="list-row-item">
                    <a
                      href={data?.linkedin || "linkedin.com"}
                      className="btn-social linkedin"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                  <li className="list-row-item">
                    <a
                      href={data?.instagram || "instagram.com"}
                      className="btn-social instagram "
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li className="list-row-item">
                    <a
                      href={data?.tiktok || "tiktok.com"}
                      className="btn-social tiktok"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTiktok} />
                    </a>
                  </li>
                </ul>
                <iframe
                  title="location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.8962993746195!2d31.10115257619888!3d29.953661322865194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14584f1335d9e6ad%3A0x9bce5fde6174108d!2sMenkaure%20Gate%20(Gate%203)%20Hadayek%20Al%20Ahram!5e0!3m2!1sen!2seg!4v1722499280000!5m2!1sen!2seg"
                  width="100%"
                  height="250"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-md-8 p-2">
              <div className="form-box box txt-start p-4 h-100 position-relative">
                <div className="form-group form-group-name">
                  <input
                    value={name}
                    onChange={onChangeName}
                    type="text"
                    className="custom-input"
                    name="name"
                    placeholder={t("contactUsPage.name")}
                    required=""
                  />
                </div>
                <div className="form-group form-group-name">
                  <input
                    value={phone}
                    onChange={onChangePhone}
                    placeholder={t("contactUsPage.phone")}
                    type="text"
                    className="custom-input"
                    name="phone"
                    minLength={11}
                    maxLength={11}
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    value={message}
                    onChange={onChangeMessage}
                    className="custom-input"
                    rows="8"
                    name="message"
                    placeholder={t("contactUsPage.message")}
                    required
                  ></textarea>
                </div>
                <div className="form-group position-absolute bottom-0 w-95 my-3">
                  <BtnComponent
                    onClick={onSubmit}
                    loading={
                      isPress === true && loading === true ? true : false
                    }
                    type={"submit"}
                    value={t("contactUsPage.submit")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactUsPage;
