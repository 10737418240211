import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetLandDetails } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";

const GetLandDetailsHook = (id) => {
  const [loading, setLoading] = useState(false);
  const [landDetails, setLandDetails] = useState([]);

  const res = useSelector(
    (state) => state.rootReducer.unitsReducer.landDetails
  );

  const dispatch = useDispatch();

  const getData = async () => {
    setLoading(true);
    await dispatch(GetLandDetails(id));
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    if (res?.status === 200) {
      setLandDetails(res.data);
    }
  }, [res]);

  return [loading, landDetails];
};

export default GetLandDetailsHook;
