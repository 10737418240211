import React from "react";
import NewsCard from "../../components/News/NewsCard/NewsCard";
import Footer from "../../components/GeneralSections/Footer/Footer";
import ScrollToTopBtn from "../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import { useTranslation } from "react-i18next";
import GetAllNewsHook from "../../CustomHooks/News/GetAllNewsHook";
import CustomPagination from "../../components/Utility/CustomPagination/CustomPagination";
const NewsPage = () => {
  const [t] = useTranslation();
  const [loading, news, newsCount, meta, onPressPaginate] = GetAllNewsHook();

  return (
    <div className="news-page page">
      <CustomHelmet
        title={"News"}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader breadcrumbTitle={"News"} newsActive={"newsActive"} />
      <section>
        <div className="container">
          <div className="row min-h-100">
            {loading === false
              ? newsCount > 0
                ? news?.map((item) => {
                    return (
                      <div className="col-12 col-lg-6 " key={item?.id}>
                        <NewsCard item={item} />
                      </div>
                    );
                  })
                : null
              : null}
          </div>
          <div className="pagination-content d-flex align-items-center justify-content-center">
            <CustomPagination
              totalPages={meta?.last_page}
              currentPage={meta?.current_page}
              onChange={onPressPaginate}
            />
          </div>
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default NewsPage;
