import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {usePostDataWithImage } from "../../../../Hooks/PostDataHook";

const initialState = {
    addUnit:[],
    error:null,
    loading:false
}
export const addUnit = createAsyncThunk("userUnit/add",async(formData,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await usePostDataWithImage('/units',formData)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})

const UserUnitSlice = createSlice({
    name:"UserUnitSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(addUnit.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(addUnit.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.addUnit = action?.payload
        })
        .addCase(addUnit.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.addUnit = action?.payload
        })
    }
})
export const UserUnitReducer = UserUnitSlice.reducer
export const UserUnitActions = UserUnitSlice.actions