import React from "react";
import './SectionTitle.css'
const SectionTitle = ({title,des}) => {
  return (
    <div className="section-title">
      <h2 className="text-center text-capitalize fw-bold">{title}</h2>
      <p className="text-center text-capitalize">{des}</p>
    </div>
  );
};

export default SectionTitle;
