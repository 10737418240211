import React from "react";
import "./ListContentNav.css";
import SelectComponent from "../../Utility/SelectComponent/SelectComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faGrip, faList } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import FilterHook from "../../../CustomHooks/FiltrationHooks/FilterHook";
import { useDispatch } from "react-redux";
import { changeFilterSidebarActive } from "../../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice";
import FilterSidebar from "../FilterSidebar/FilterSidebar";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
const ListContentNav = ({ changeActive, active, unitsType }) => {
  const [t] = useTranslation();

  const [
    minSpace,
    maxSpace,
    rooms,
    bathrooms,
    type,
    searchWord,
    property_id,
    category_id,
    minPrice,
    maxPrice,
    onChangeMinSpace,
    onChangeMaxSpace,
    onChangeBathrooms,
    onChangeType,
    onChangeSearchWord,
    onChangeRooms,
    onChangePropertyId,
    onChangeCategoryId,
    onChangeMaxPrice,
    onChangeMinPrice,
    onSearch,
    onSearchSidebar,
    Reset,
    sort,
    onChangeSort,
    onChangeCode,
    code,
    onChangeMainCategory,
    gateId,
    onChangeGateId,
    onChangeFinishing,
    onChangeFurnished,
    onChangeDistrictId,
    paying,
    onChangePaying,
    delivery_date,
    on_change_delivery_date,
    delivery_end_date,
    on_change_delivery_end_date,
    delivery_start_date,
    on_change_delivery_start_date,
    fawry,
    on_change_fawry,
    years,
    on_change_years,
    down_payment,
    on_change_down_payment,
    on_change_finance,
    onChangeDeposit,
    onChangeMinDeposit,
    onChangeMaxDeposit,
    onChangeInstallment,
    onChangeMinInstallment,
    onChangeMaxInstallment,
    handleSearchWord,
  ] = FilterHook(unitsType);

  const sort_options = [
    { value: "low-to-heigh", label: t("listingPage.listContent.lowTo") },
    { value: "heigh-to-low", label: t("listingPage.listContent.highTo") },
  ];

  const dispatch = useDispatch();
  const handleChangeFilterSidebarActive = () => {
    dispatch(changeFilterSidebarActive());
  };

  return (
    <nav className="d-flex align-items-center justify-content-between flex-wrap">
      <div className="show-filter position-relative transition d-flex align-items-center">
        <BtnComponent
          st={"wd-fit"}
          value={t("listingPage.listContent.filter")}
          icon={<FontAwesomeIcon icon={faFilter} />}
          onClick={() => handleChangeFilterSidebarActive()}
        />
        <ul
          className="nav nav-pills nav-pills-1 myTab d-flex m-0 align-items-center"
          role="tablist"
        >
          <li className="list-inline-item mr-auto w-100">
            {/* <span className="title-text mx-2">{t("listingPage.listContent.sortBy")}</span> */}
            <div className="select-wrapper">
              <SelectComponent
                options={sort_options}
                placeholder={t("listingPage.listContent.basedProperties")}
                onChange={onChangeSort}
              />
            </div>
          </li>
        </ul>
      </div>
      <div className="form-group flex-fill d-flex justify-content-between gap-3 align-items-center">
        <input
          className="custom-input"
          value={searchWord}
          onChange={onChangeSearchWord}
          type="search"
          placeholder={t("listingPage.listingSidebar.buildingSearch")}
        />
        <BtnComponent
          st={"wd-fit"}
          value={t("listingPage.listingSidebar.search")}
          icon={<FontAwesomeIcon icon={faFilter} />}
          onClick={() => handleSearchWord()}
        />
      </div>
      <div className="d-none d-sm-block">
        <ul className="nav-pills myTab m-0" role="tablist">
          <li
            className={
              active === "vertical-cards"
                ? "nav-item transition active"
                : "nav-item transition"
            }
            onClick={() => changeActive("vertical-cards")}
          >
            <FontAwesomeIcon icon={faGrip} />
          </li>
          <li
            className={
              active === "horizontal-cards"
                ? "nav-item transition active"
                : "nav-item transition"
            }
            onClick={() => changeActive("horizontal-cards")}
          >
            <FontAwesomeIcon icon={faList} />
          </li>
        </ul>
      </div>
      <FilterSidebar unitsType={unitsType} />
    </nav>
  );
};

export default ListContentNav;
