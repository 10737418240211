import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";

const initialState = {
  allOffers: [],
  offerDetails: [],
  error: null,
  loading: true,
};

export const GetAllOffers = createAsyncThunk(
  "allOffers",
  async (page, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(`/offers?per_page=20&page=${page}`);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GetOfferDetails = createAsyncThunk(
  "offersDetails",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(`/offers/${id}`);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const OffersSlice = createSlice({
  name: "OffersSlice",
  initialState,
  extraReducers: (builder) => {
    builder
      // get all service list
      .addCase(GetAllOffers.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAllOffers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allOffers = action?.payload?.data;
      })
      .addCase(GetAllOffers.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.data;
        state.allOffers = action?.payload?.data;
      })
      // service details
      .addCase(GetOfferDetails.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetOfferDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.offerDetails = action?.payload;
      })
      .addCase(GetOfferDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.offerDetails = action?.payload;
      });
  },
});
export const offersReducer = OffersSlice.reducer;
export const offersActions = OffersSlice.actions;
