import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { usePostData } from "../../../../Hooks/PostDataHook";

const initialState = {
    contactUs:[],
    error:null,
    loading:true
}
export const contactUs = createAsyncThunk("contact-us",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await usePostData('/contact_us',params)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
const ContactUsSlice = createSlice({
    name:"ContactUsSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(contactUs.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(contactUs.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.contactUs = action?.payload
        })
        .addCase(contactUs.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.contactUs = action?.payload
        })
    }
})
export const ContactUsReducer = ContactUsSlice.reducer
export const ContactUsSliceActions = ContactUsSlice.actions