import React from "react";
import "./NewsCard.css";
import news_img from "../../../images/anonymous-image.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import Cookies from "js-cookie";
import DisplayWordpadContent from "../../Utility/DisplayWordpadContent/DisplayWordpadContent";
import { useTranslation } from "react-i18next";
const NewsCard = ({ item }) => {
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();
  return (
    <div className="news-card blog__grid my-4 overflow-hidden">
      <div className="card-content h-100">
        <img
          src={item?.images?.[0]?.image || news_img}
          alt="new-img"
          className="w-100 h-100 img-transition"
          loading="lazy"
        />
        <div className="card-body txt-start p-3 h-100">
          <h6 className="text-capitalize color-sec2 fw-bold fs-16">
            {lang === "en"
              ? item?.data_trans?.[0]?.title_ar
              : item?.data_trans?.[1]?.title_en}
          </h6>
          <p className="color-primary fw-bold m-0 fs-14">
            {lang === "en"
              ? item?.categories?.[0]?.data_trans?.[0]?.title_ar
              : item?.categories?.[0].data_trans?.[1]?.title_en}
          </p>
          <div className="description fs-14 h-35px overflow-hidden">
            <DisplayWordpadContent
              content={
                lang === "en"
                  ? item?.data_trans?.[0]?.description_ar?.slice(0, 80)
                  : item?.data_trans?.[1]?.description_en?.slice(0, 80)
              }
            />
          </div>
          <Link to={`/news-details/${item?.id}`}>
            <BtnComponent
              st={"wd-fit radius-10 py-1 px-3 fs-14"}
              value={t("newsPage.readMore")}
              icon={<FontAwesomeIcon icon={faArrowCircleUp} />}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
