import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {usePostDataToken, usePostDataWithImage } from "../../../../Hooks/PostDataHook";

const initialState = {
    profileUpdatedInfo:[],
    updatePass:[],
    error:null,
    loading:false
}
export const profileUpdate = createAsyncThunk("profile/profileUpdate",async(formData,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await usePostDataWithImage('/profile/update',formData)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
export const updatePass = createAsyncThunk("profile/updatePass",async(formData,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await usePostDataToken('/user/update',formData)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
const UserSettingSlice = createSlice({
    name:"UserSettingSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(profileUpdate.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(profileUpdate.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.profileUpdatedInfo = action?.payload
        })
        .addCase(profileUpdate.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.profileUpdatedInfo = action?.payload
        })
        // update password
        .addCase(updatePass.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(updatePass.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.updatePass = action?.payload
        })
        .addCase(updatePass.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.updatePass = action?.payload
        })

    }
})
export const settingReducer = UserSettingSlice.reducer
export const settingActions = UserSettingSlice.actions