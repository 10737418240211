import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeActive } from "../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice";
import UserProfileHook from "../../CustomHooks/User/UserProfileHook";
import GetAllCategoriesHook from "../../CustomHooks/Categories/GetAllCategoriesHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import OurInfoHook from "../../CustomHooks/OurSettings/OurInfo/OurInfoHook";
const InternalNavbarHook = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(changeActive());
  };
  const [user, , data] = UserProfileHook();
  const [categories_loading, categories] = GetAllCategoriesHook();

  const Navigate = useNavigate();

  const handleCategoryClick = (id, val) => {
    Navigate(`/listing-page?category=${id}&type=${val}`);
  };

  const lang = Cookies.get("i18next");
  const { t } = useTranslation();
  const [dataI] = OurInfoHook();

  return [
    dataI,
    data,
    categories,
    categories_loading,
    handleCategoryClick,
    lang,
    t,
    handleClick,
    user,
  ];
};

export default InternalNavbarHook;
