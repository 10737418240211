import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {useGetData} from '../../../../Hooks/GetDataHook'
const initialState = {
    ourInfo:[],
    error:null,
    loading:true
}
export const getOurInfo = createAsyncThunk("OurInfo",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/setting',params)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
const OurInfoSlice = createSlice({
    name:"OurInfoSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(getOurInfo.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(getOurInfo.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.ourInfo = action?.payload
        })
        .addCase(getOurInfo.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.ourInfo = action?.payload
        })
    }
})
export const OurInfoReducer = OurInfoSlice.reducer
export const OurInfoActions = OurInfoSlice.actions