import {useState,useEffect} from 'react'
import {deleteFromWishlist} from "../../Redux/Redux-Toolkit/Slices/Wishlist/WishlistSlice"
import { useSelector ,useDispatch} from 'react-redux';
import Notify from '../UseNotification/UseNotification';

const DeleteFromWishlistHook = () => {
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(true)
    const res = useSelector((state) => state.rootReducer.wishlistReducer.deleteFromWishlist);

    const deleteFromWishlistFn = async(id)=>{
        setLoading(true)
        await dispatch(deleteFromWishlist(id))
        setLoading(false)
    }
    useEffect(()=>{
        if(loading===false){
            if(res){
                if(res?.status === 200){
                    Notify("The Deletion Done", "success");
                }else{
                    Notify("there is a problem", "error");
                }
            }
        }
    },[loading])

    return [deleteFromWishlistFn,loading,res]
}

export default DeleteFromWishlistHook
