import React from "react";
import "./SearchBox.css";
import BtnComponent from "../../../Utility/BtnComponent/BtnComponent";
import SelectComponent from "../../../Utility/SelectComponent/SelectComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SearchBoxHook from "../../../../CustomHooks/SearchBox/SearchBoxHook";
import { Link } from "react-router-dom";
const SearchBox = () => {
  const [
    categoryList,
    active,
    roomsList,
    bathRoomsList,
    gatesOptions,
    ,
    onChangeBathrooms,
    ,
    onChangeRooms,
    onChangeCategoryId,
    onSearch,
    ,
    ,
    handleChangeActive,
    t,
    ,
    ,
    ,
    ,
    ,
    ,
    DistrictsOptions,
    onChangeGateId,
    onChangeDistrictId,
  ] = SearchBoxHook();

  return (
    <div className="search-box">
      <div className="wrap__intro">
        <div className="container">
          <div className="search-content-wrapper align-items-center justify-content-start flex-wrap">
            <div className="mx-auto">
              <div className="wrap__intro-heading">
                <div className="intro-title text-center mb-3">
                  <h1 className="des text-capitalize second-title">
                    {t("generalSections.landing.searchBox.findYourDreamHouse")}
                  </h1>
                  {/* <p>{t("generalSections.landing.searchBox.description")}</p> */}
                </div>
                <div className="search__property">
                  <div className="position-relative">
                    <ul
                      className="nav mb-3 justify-content-start"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li
                        onClick={() => handleChangeActive("buy")}
                        className={
                          active === "buy" ? "nav-item  active" : "nav-item "
                        }
                      >
                        {t("generalSections.landing.searchBox.sale")}
                      </li>
                      <li
                        className={
                          active === "rent" ? "nav-item  active" : "nav-item "
                        }
                        onClick={() => handleChangeActive("rent")}
                      >
                        {t("generalSections.landing.searchBox.rent")}
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className={
                          active === "buy"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="buy"
                        role="tabpanel"
                        aria-labelledby="buy-tab"
                      >
                        <div className="search__container text-start">
                          <div className="row">
                            <div className="col-lg-10 no-gutters select-lists">
                              <div className="row w-100">
                                <div className="col-lg-3 p-0 select-list-item txt-start">
                                  <label className="fs-16 fw-medium text-secondary mb-2">
                                    {t(
                                      "generalSections.landing.searchBox.category"
                                    )}
                                  </label>
                                  <SelectComponent
                                    options={categoryList[0]}
                                    placeholder={t(
                                      "generalSections.landing.searchBox.category"
                                    )}
                                    onChange={onChangeCategoryId}
                                  />
                                </div>
                                <div className="col-lg-3 p-0 select-list-item txt-start">
                                  <label className="fs-16 fw-medium text-secondary mb-2">
                                    {t(
                                      "generalSections.landing.searchBox.rooms"
                                    )}
                                  </label>
                                  <SelectComponent
                                    options={roomsList}
                                    placeholder={t(
                                      "generalSections.landing.searchBox.rooms"
                                    )}
                                    onChange={onChangeRooms}
                                  />
                                </div>
                                <div className="col-lg-3 p-0 select-list-item txt-start">
                                  <label className="fs-16 fw-medium text-secondary mb-2">
                                    {t(
                                      "generalSections.landing.searchBox.bathRooms"
                                    )}
                                  </label>
                                  <SelectComponent
                                    options={bathRoomsList}
                                    placeholder={t(
                                      "generalSections.landing.searchBox.bathRooms"
                                    )}
                                    onChange={onChangeBathrooms}
                                  />
                                </div>
                                <div className="col-lg-3 p-0 select-list-item txt-start">
                                  <label className="fs-16 fw-medium text-secondary mb-2">
                                    {t(
                                      "generalSections.landing.searchBox.gate"
                                    )}
                                  </label>
                                  <SelectComponent
                                    options={gatesOptions[0]}
                                    placeholder={t(
                                      "generalSections.landing.searchBox.gate"
                                    )}
                                    onChange={onChangeGateId}
                                  />
                                </div>
                                {/* <div className="col-lg-2 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={DistrictsOptions[0]}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.District"
                                  )}
                                  onChange={onChangeDistrictId}
                                />
                              </div> */}
                              </div>
                            </div>
                            <div className="col-lg-2 p-0 input-group-append txt-start d-flex flex-column justify-content-end">
                              <div
                                className="btn-box search-btn"
                                onClick={onSearch}
                              >
                                <BtnComponent
                                  type={"submit"}
                                  st={"radius-10"}
                                  value={t(
                                    "generalSections.landing.searchBox.search"
                                  )}
                                  icon={<FontAwesomeIcon icon={faSearch} />}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          active === "rent"
                            ? "tab-pane fade active show"
                            : "tab-pane fade"
                        }
                        id="rent"
                        role="tabpanel"
                        aria-labelledby="rent-tab"
                      >
                        <div className=" search__container text-start">
                          <div className="row">
                            <div className="col-lg-10 no-gutters select-lists">
                              <div className="row w-100">
                                <div className="col-lg-3 p-0 select-list-item txt-start">
                                  <label className="fs-16 fw-medium text-secondary mb-2">
                                    {t(
                                      "generalSections.landing.searchBox.category"
                                    )}
                                  </label>
                                  <SelectComponent
                                    options={categoryList[0]}
                                    placeholder={t(
                                      "generalSections.landing.searchBox.category"
                                    )}
                                    onChange={onChangeCategoryId}
                                  />
                                </div>
                                <div className="col-lg-3 p-0 select-list-item txt-start">
                                  <label className="fs-16 fw-medium text-secondary mb-2">
                                    {t(
                                      "generalSections.landing.searchBox.rooms"
                                    )}
                                  </label>
                                  <SelectComponent
                                    options={roomsList}
                                    placeholder={t(
                                      "generalSections.landing.searchBox.rooms"
                                    )}
                                    onChange={onChangeRooms}
                                  />
                                </div>
                                <div className="col-lg-3 p-0 select-list-item txt-start">
                                  <label className="fs-16 fw-medium text-secondary mb-2">
                                    {t(
                                      "generalSections.landing.searchBox.bathRooms"
                                    )}
                                  </label>
                                  <SelectComponent
                                    options={bathRoomsList}
                                    placeholder={t(
                                      "generalSections.landing.searchBox.bathRooms"
                                    )}
                                    onChange={onChangeBathrooms}
                                  />
                                </div>
                                <div className="col-lg-3 p-0 select-list-item txt-start">
                                  <label className="fs-16 fw-medium text-secondary mb-2">
                                    {t(
                                      "generalSections.landing.searchBox.gate"
                                    )}
                                  </label>
                                  <SelectComponent
                                    options={gatesOptions[0]}
                                    placeholder={t(
                                      "generalSections.landing.searchBox.gate"
                                    )}
                                    onChange={onChangeGateId}
                                  />
                                </div>
                                {/* <div className="col-lg-2 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={DistrictsOptions[0]}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.District"
                                  )}
                                  onChange={onChangeDistrictId}
                                />
                              </div> */}
                              </div>
                            </div>
                            <div className="col-lg-2 p-0 input-group-append txt-start d-flex flex-column justify-content-end">
                              <div
                                className="btn-box search-btn"
                                onClick={onSearch}
                              >
                                <BtnComponent
                                  type={"submit"}
                                  st={"radius-10"}
                                  value={t(
                                    "generalSections.landing.searchBox.search"
                                  )}
                                  icon={<FontAwesomeIcon icon={faSearch} />}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <nav className="text-center my-5">
                <ul className="d-flex align-items-center justify-content-center flex-wrap p-3">
                  <li className="text-white rounded pointer fs-20 fw-medium border border-2 border-white transition my-2 mx-3">
                    <Link
                      to={"/listing-page"}
                      className="text-white w-100 h-100 d-block p-3"
                    >
                      {t("generalSections.landing.searchBox.units")}
                    </Link>
                  </li>
                  <li className="text-white rounded pointer fs-20 fw-medium border border-2 border-white transition my-2 mx-3">
                    <Link
                      to={"/installment"}
                      className="text-white w-100 h-100 d-block p-3"
                    >
                      {t("generalSections.landing.searchBox.installment")}
                    </Link>
                  </li>
                  <li className="text-white rounded pointer fs-20 fw-medium border border-2 border-white transition my-2 mx-3">
                    <Link
                      to={"/offers"}
                      className="text-white w-100 h-100 d-block p-3"
                    >
                      {t("generalSections.landing.searchBox.offers")}
                    </Link>
                  </li>
                  <li className="text-white rounded pointer fs-20 fw-medium border border-2 border-white transition my-2 mx-3">
                    <Link
                      to={"/finance"}
                      className="text-white w-100 h-100 d-block p-3"
                    >
                      {t("generalSections.landing.searchBox.finance")}
                    </Link>
                  </li>
                  <li className="text-white rounded pointer fs-20 fw-medium border border-2 border-white transition my-2 mx-3">
                    <Link
                      to={"/lands"}
                      className="text-white w-100 h-100 d-block p-3"
                    >
                      {t("generalSections.landing.searchBox.lands")}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
