import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { postJobApply } from "../../Redux/Redux-Toolkit/Slices/Jobs/JobsSlice";
import Notify from "../UseNotification/UseNotification";
import { useNavigate, useParams } from "react-router-dom";
import useLang from "../helpers/useLang";

const JobApplyHook = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { lang } = useLang();
  const { id } = useParams();
  const response = useSelector(
    (state) => state.rootReducer.jobsReducer.jobApplyRes
  );
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [contact_number, set_contact_number] = useState("");
  const [email, set_email] = useState("");
  const [notice_period, set_notice_period] = useState("");
  const [work_link, set_work_link] = useState("");
  const [resume, set_resume] = useState();
  const [current_salary, set_current_salary] = useState("");
  const [expected_salary, set_expected_salary] = useState("");
  const [loading, set_loading] = useState(true);
  const [isPress, setIsPress] = useState(false);
  const [t] = useTranslation();

  // function for states
  const onChangeFirstName = (e) => {
    set_first_name(e.target.value);
  };
  const onChangeLastName = (e) => {
    set_last_name(e.target.value);
  };
  const onChangeEmail = (e) => {
    set_email(e.target.value);
  };
  const onChangeContactNumber = (e) => {
    set_contact_number(e.target.value);
  };
  const onChangeNoticePeriod = (e) => {
    set_notice_period(e.target.value);
  };
  const onChangeWorkLink = (e) => {
    set_work_link(e.target.value);
  };
  const onChangeResume = (e) => {
    set_resume(e.target.files[0]);
  };

  const onChangeCurrentSalary = (e) => {
    set_current_salary(e.target.value);
  };
  const onChangeExpectedSalary = (e) => {
    set_expected_salary(e.target.value);
  };
  // on submit fn
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsPress(true);
    set_loading(true);
    const formData = new FormData();
    formData.append("job_id", id);
    formData.append("email", email);
    formData.append("contact_number", contact_number);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("notice_period", notice_period);
    formData.append("work_link", work_link);
    formData.append("resume", resume);
    formData.append("current_salary", current_salary);
    formData.append("expected_salary", expected_salary);
    await dispatch(postJobApply(formData));
    set_loading(false);
    setIsPress(false);
  };

  useEffect(() => {
    if (loading === false) {
      if (response) {
        console.log(response);
        if (response?.data?.status === 201) {
          Notify(response?.data?.message, "success");
          setTimeout(() => {
            Navigate("/jobs");
          }, 1500);
        } else if (response?.response?.status === 400) {
          Notify(
            lang === "ar"
              ? response?.response?.data?.errors?.[0]?.arabic_message?.[0]
              : response?.response?.data?.errors?.[0]?.english_message?.[0],
            "error"
          );
        } else {
          Notify("there is a problem", "error");
        }
      }
    }
  }, [response, loading]);

  return [
    first_name,
    onChangeFirstName,
    last_name,
    onChangeLastName,
    contact_number,
    onChangeContactNumber,
    email,
    onChangeEmail,
    notice_period,
    onChangeNoticePeriod,
    work_link,
    onChangeWorkLink,
    resume,
    onChangeResume,
    current_salary,
    onChangeCurrentSalary,
    expected_salary,
    onChangeExpectedSalary,
    onSubmit,
    loading,
    isPress,
    t,
  ];
};

export default JobApplyHook;
