import React from "react";
import Footer from "../../../components/GeneralSections/Footer/Footer";
import ScrollToTopBtn from "../../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import ProjectSidebar from "../../../components/ReasEstateProject/ProjectSidebar/ProjectSidebar";
import { useParams } from "react-router-dom";
import AgencyCard from "../../../components/Agency/AgencyCard/AgencyCard";
import AgencyCardSkeleton from "../../../components/Agency/AgencyCardSkeleton/AgencyCardSkeleton";
import HorizontalCardSkeleton from "../../../components/Utility/Cards/HorizontalCardSkeleton/HorizontalCardSkeleton";
import HorizontalCard from "../../../components/Utility/Cards/HorizontalCard/HorizontalCard";
import { useTranslation } from "react-i18next";
// import Cookies from "js-cookie"
import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import GetAgencyDetailsHook from "../../../CustomHooks/AgencyHook/GetAgencyDetailsHook";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const AgencyDetailsPage = () => {
  const { id } = useParams();
  const [loading,agencyDetails] = GetAgencyDetailsHook(id?.slice(1,id.length))
  // const lang= Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="agency-details-page page">
        <CustomHelmet
      title={t("SEO.meta.title")}
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <InternalHeader agencyActive={"agencyActive"}/>
      <BreadcrumbSection title= {"agency details"} />
      <section className="profile__agents">
        <div className="container">
          <div className="row min-vh-100">
            <div className="col-lg-8">
              <div className="row no-gutters">
                <div className="col-lg-12 cards mt-0">
                  {loading === false ? (
                    <AgencyCard item={agencyDetails} />
                  ) : (
                    <AgencyCardSkeleton />
                  )}
                </div>
              </div>
              <div className="single__detail-features tabs__custom">
                <h5 className="text-capitalize detail-heading fw-bold color-primary">
                 {t("projectDetailsPage.title")}
                </h5>
                <ul className="nav nav-pills myTab p-2 my-2" role="tablist">
                  <li
                    role="button"
                    className="nav-item text-capitalize fw-bold"
                  >
                    <span
                      className="nav-link active pills-tab-two"
                    >
                      {t("projectDetailsPage.units")}
                    </span>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-tab-two"
                    role="tabpanel"
                    aria-labelledby="pills-tab-two"
                  >
                    <div className="row">
                      {loading === false ? (
                        agencyDetails?.units?.map((item) => {
                          return (
                            <div className="col-lg-12" key={item?.id}>
                              <HorizontalCard item={item}/>
                            </div>
                          );
                        })
                      ) : (
                        <>
                          <HorizontalCardSkeleton />
                          <HorizontalCardSkeleton />
                          <HorizontalCardSkeleton />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <ProjectSidebar />
            </div>
          </div>
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default AgencyDetailsPage;
