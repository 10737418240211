import React from "react";
import ScrollToTopBtn from "../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Footer from "../../components/GeneralSections/Footer/Footer";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
// import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import AboutUsHook from "../../CustomHooks/OurSettings/AboutUs/AboutUsHook";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import DisplayWordPadContent from "../../components/Utility/DisplayWordpadContent/DisplayWordpadContent";
import WantToSell from "../../components/Home/WantToSell/WantToSell";
import OurMessageHook from "../../CustomHooks/OurSettings/AboutUs/OurMessageHook";
import OurMissionHook from "../../CustomHooks/OurSettings/AboutUs/OurMissionHook";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faEnvelope,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
const AboutUsPage = () => {
  const [data, loading] = AboutUsHook();
  const [ourMessageData, ourMessageLoading] = OurMessageHook();
  const [ourMissionData, ourMissionLoading] = OurMissionHook();
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();

  return (
    <div className="about-us-page page">
      <CustomHelmet
        title={t("aboutUsPage.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader aboutUsActive={"aboutUsActive"} />
      {/* <BreadcrumbSection title={t("aboutUsPage.title")} /> */}
      <section className="home__about bg-light">
        <div className="container min-vh-100">
          <div className="row my-5">
            <div className="col-lg-4 txt-center">
              {loading === false ? (
                data ? (
                  <div className="box">
                    <span className="color-sec fs-55">
                      <FontAwesomeIcon icon={faUsers} />
                    </span>
                    <h2 className="text-capitalize my-3 color-primary">
                      {lang === "en"
                        ? data?.data_trans?.[1]?.title_en
                        : data?.data_trans?.[0]?.title_ar}
                    </h2>
                    {lang === "en" ? (
                      <DisplayWordPadContent
                        content={data?.data_trans?.[1]?.description_en}
                      />
                    ) : (
                      <DisplayWordPadContent
                        content={data?.data_trans?.[0]?.description_ar}
                      />
                    )}
                  </div>
                ) : null
              ) : (
                <>
                  <Skeleton height={40} />
                  <Skeleton height={40} sx={{ margin: "5px 0" }} />
                  <Skeleton height={200} />
                </>
              )}
            </div>
            <div className="col-lg-4 txt-center">
              {ourMessageLoading === false ? (
                ourMessageData ? (
                  <div className="box">
                    <span className="color-sec fs-55">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <h2 className="text-capitalize my-3 color-primary">
                      {lang === "en"
                        ? ourMessageData?.data_trans?.[1]?.title_en
                        : ourMessageData?.data_trans?.[0]?.title_ar}
                    </h2>
                    {lang === "en" ? (
                      <DisplayWordPadContent
                        content={
                          ourMessageData?.data_trans?.[1]?.description_en
                        }
                      />
                    ) : (
                      <DisplayWordPadContent
                        content={
                          ourMessageData?.data_trans?.[0]?.description_ar
                        }
                      />
                    )}
                  </div>
                ) : null
              ) : (
                <>
                  <Skeleton height={40} />
                  <Skeleton height={40} sx={{ margin: "5px 0" }} />
                  <Skeleton height={200} />
                </>
              )}
            </div>
            <div className="col-lg-4 txt-center">
              {ourMissionLoading === false ? (
                ourMissionData ? (
                  <div className="box">
                    <span className="color-sec fs-55">
                      <FontAwesomeIcon icon={faBullseye} />
                    </span>
                    <h2 className="text-capitalize my-3 color-primary">
                      {lang === "en"
                        ? ourMissionData?.data_trans?.[1]?.title_en
                        : ourMissionData?.data_trans?.[0]?.title_ar}
                    </h2>
                    {lang === "en" ? (
                      <DisplayWordPadContent
                        content={
                          ourMissionData?.data_trans?.[1]?.description_en
                        }
                      />
                    ) : (
                      <DisplayWordPadContent
                        content={
                          ourMissionData?.data_trans?.[0]?.description_ar
                        }
                      />
                    )}
                  </div>
                ) : null
              ) : (
                <>
                  <Skeleton height={40} />
                  <Skeleton height={40} sx={{ margin: "5px 0" }} />
                  <Skeleton height={200} />
                </>
              )}
            </div>
          </div>
        </div>
        <WantToSell />
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default AboutUsPage;
