// to show the notifications
import {Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const style ={
  position:"top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  transition:Bounce
}
const Notify =(msg,type)=>{
    if(type=== "warn"){
      return toast.warn(msg,style);
    }else if(type === "success"){
      return toast.success(msg,style);
    }else if(type === "error"){
      return toast.error(msg,style);
    }
  }

export default Notify