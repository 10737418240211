import React from "react";
import ScrollToTopBtn from "../../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Footer from "../../../components/GeneralSections/Footer/Footer";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import CustomPagination from "../../../components/Utility/CustomPagination/CustomPagination";
// import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection"
import ServiceCardSkeleton from "../../../components/Services/ServiceCardSkeleton/ServiceCardSkeleton";
import ServiceCategoryCard from "../../../components/Services/ServiceCategoryCard/ServiceCategoryCard";
import GetAllServicesCategoryHook from "../../../CustomHooks/Services/GetAllServicesCategoryHook";
import { useTranslation } from "react-i18next";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const ServicesPage = () => {
  const [loading, servicesCategory, onPressPaginate] =
    GetAllServicesCategoryHook();
  const [t] = useTranslation();
  return (
    <div className="services-page agency-page page">
      <CustomHelmet
        title={t("servicePage.serviceCategory")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader servicesActive={"servicesActive"} />
      {/* <BreadcrumbSection title={t("servicePage.serviceCategory")} /> */}
      <section>
        <div className="profile__agency">
          <div className="container">
            <div className="row min-vh-100 projects-wrapper no-gutters">
              {loading === false ? (
                servicesCategory?.map((item) => {
                  return (
                    <div className="col-sm-6 col-md-4 my-1" key={item?.id}>
                      <ServiceCategoryCard item={item} />
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <ServiceCardSkeleton />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <ServiceCardSkeleton />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <ServiceCardSkeleton />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <ServiceCardSkeleton />
                  </div>
                </>
              )}
            </div>
            {servicesCategory?.length > 0 && (
              <div className="pagination-content d-flex align-items-center justify-content-center">
                <CustomPagination
                  totalPages={servicesCategory?.meta?.last_page}
                  currentPage={servicesCategory?.meta?.current_page}
                  onChange={onPressPaginate}
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default ServicesPage;
