import React from "react";
import "./DisplayWordpadContent.css";
const DisplayWordpadContent = ({ content }) => {
  return (
    <div
      className="display-word-content text-wrap"
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
};

export default DisplayWordpadContent;
