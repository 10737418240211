import { Skeleton } from '@mui/material'
import React from 'react'

const DetailSkeleton = () => {
  return (
    <div className='container my-5'>
    <Skeleton variant='rounded' height={500} sx={{width:"100%"}}/>
</div>
  )
}

export default DetailSkeleton
