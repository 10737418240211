import React, { useRef, useState } from "react";
import "./ImagesSlider.css";
import "../../../css/swiper.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "../../../css/swiper.css";
// import required modules
import {
  FreeMode,
  Pagination,
  Navigation,
  Autoplay,
  Thumbs,
  Keyboard,
} from "swiper/modules";
import { useTranslation } from "react-i18next";
import SwiperCore from "swiper/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

// swiper slide content component
export const SwiperSlideContent = ({ img }) => {
  return (
    <div className="item h-100 swiper-slide-item ">
      <div className="sidlo slider__image__detail-large-one">
        <img
          src={img}
          alt="unit-img"
          className="img-transition"
          loading="lazy"
        />
      </div>
    </div>
  );
};

const ImagesSlider = ({ items }) => {
  const { t, i18n } = useTranslation();
  const langDirection = i18n.dir();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [active, setActive] = useState(false);
  SwiperCore.use([Keyboard]);

  // function CustomKeyboardSwiper() {
  const swiperRef = useRef(null);
  const handleKeydown = (e) => {
    if (e.key === "ArrowLeft") {
      swiperRef.current.swiper.slidePrev();
    } else if (e.key === "ArrowRight") {
      swiperRef.current.swiper.slideNext();
    } else if (e.key === "Enter") {
      // Perform additional actions on Enter key press
      // console.log('Enter key pressed');
      return;
    }
  };
  // }
  return (
    <div className="slide-show">
      {/*main swiper */}
      <Swiper
        spaceBetween={10}
        navigation={true}
        onClick={() => setActive(!active)}
        style={{
          "--swiper-pagination-bullet-width": "10px",
          "--swiper-pagination-bullet-height": "10px",
          "--swiper-pagination-bottom": "-4px",
          "--swiper-pagination-color": "#fc6f2e",
          "--swiper-navigation-size": "35px",
          "--swiper-navigation-width": "30px",
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
          waitForTransition: true,
        }}
        pagination={{
          clickable: true,
        }}
        //   breakpoints={{
        //     "@0.00": {
        //       slidesPerView: 1,
        //       spaceBetween: 10,
        //     },
        //     "@0.75": {
        //       slidesPerView: 2,
        //       spaceBetween: 10,
        //     },
        //     "@1.00": {
        //       slidesPerView: 3,
        //       spaceBetween: 40,
        //     },
        //   }}
        modules={[Pagination, Autoplay, Navigation]}
        dir={!langDirection ? "rtl" : "ltr"}
        key={langDirection}
        className="image-slider similar-properties-swiper"
      >
        {items?.map((item) => {
          return (
            <SwiperSlide key={item?.id}>
              <img
                className="w-100 radius-10"
                src={item?.image}
                alt="unit-img"
                loading="lazy"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/*popup swiper */}
      <div className={active === true ? "popup-swiper active" : "popup-swiper"}>
        <div className="popup-swiper-wrapper">
          <div
            className="swiper-close-btn transition"
            onClick={() => setActive(!active)}
          >
            <FontAwesomeIcon icon={faX} />
          </div>
          <Swiper
            style={{
              "--swiper-pagination-bullet-width": "10px",
              "--swiper-pagination-bullet-height": "10px",
              "--swiper-pagination-bottom": "-5px",
              "--swiper-pagination-color": "#f16022",
              "--swiper-navigation-color": "#fff",
            }}
            pagination={{
              clickable: true,
            }}
            keyboard={{
              enabled: true,
              onKeydown: handleKeydown,
            }}
            loop={false}
            spaceBetween={10}
            navigation={true}
            thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
            modules={[FreeMode, Pagination, Navigation, Thumbs]}
            dir={!langDirection ? "rtl" : "ltr"}
            key={langDirection}
            className="mySwiper2"
          >
            {items?.map((item) => {
              return (
                <SwiperSlide key={item?.id}>
                  <SwiperSlideContent img={item?.image} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default ImagesSlider;
