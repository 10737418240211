import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";
const initialState = {
    allCategories:[],
    error:null,
    loading:true
}
export const GetAllCategories = createAsyncThunk("allCategories",async(queryString,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/categories')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
const CategoriesSlice = createSlice({
    name:"categoriesSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllCategories.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllCategories.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allCategories =action?.payload?.data
        })
        .addCase(GetAllCategories.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.data
            state.allCategories =action?.payload?.data
        })
        
    }
})
export const categoriesReducer = CategoriesSlice.reducer
export const categoriesActions = CategoriesSlice.actions