import React, { useState } from "react";
import BtnComponent from "../../../components/Utility/BtnComponent/BtnComponent";
import AgentSidebar from "../../../components/Agents/AgentSidebar/AgentSidebar";
import AgentCard from "../../../components/Agents/AgentCard/AgentCard";
import RealStateCard from "../../../components/Utility/RealStateCard/RealStateCard";
import ReadMore from "../../../components/Utility/ReadMore/ReadMore";
import person_img from "../../../images/anonymous.png";
import Footer from "../../../components/GeneralSections/Footer/Footer";
import ScrollToTopBtn from "../../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Cta from "../../../components/Home/Cta/Cta";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import { useTranslation } from "react-i18next";
const AgentDetailsPage = () => {
  const [active, setActive] = useState("des");
  const [t] = useTranslation()
  return (
    <div className="agent-details-page page">
       <CustomHelmet
      title={t("SEO.meta.title")}
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <InternalHeader
        breadcrumbTitle={"Agent Details"}
        agentsActive={"agentsActive"}
      />
      <section className="profile__agents">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <AgentCard />
              <div className="single__detail-features tabs__custom">
                <h5 className="text-capitalize my-3 fw-bold detail-heading">
                  Agent details
                </h5>
                <ul className="nav nav-pills myTab p-2" role="tablist">
                  <li
                    role="button"
                    className="nav-item"
                    onClick={() => setActive("des")}
                  >
                    <span
                      className={
                        active === "des"
                          ? "nav-link active pills-tab-one"
                          : "nav-link pills-tab-one"
                      }
                    >
                      Description
                    </span>
                  </li>
                  <li
                    role="button"
                    className="nav-item"
                    onClick={() => setActive("listing")}
                  >
                    <span
                      className={
                        active === "listing"
                          ? "nav-link active pills-tab-two"
                          : "nav-link pills-tab-two"
                      }
                    >
                      Listing
                    </span>
                  </li>
                  <li
                    role="button"
                    className="nav-item"
                    onClick={() => setActive("reviews")}
                  >
                    <span
                      className={
                        active === "reviews"
                          ? "nav-link active pills-tab-three"
                          : "nav-link pills-tab-three"
                      }
                    >
                      Reviews
                    </span>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className={
                      active === "des"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-tab-one"
                    role="tabpanel"
                    aria-labelledby="pills-tab-one"
                  >
                    <ReadMore />
                    <div className="clearfix"></div>
                  </div>
                  <div
                    className={
                      active === "listing"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-tab-two"
                    role="tabpanel"
                    aria-labelledby="pills-tab-two"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <RealStateCard />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <RealStateCard />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <RealStateCard />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <RealStateCard />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <RealStateCard />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <RealStateCard />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <RealStateCard />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div
                    className={
                      active === "reviews"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-tab-four"
                    role="tabpanel"
                    aria-labelledby="pills-tab-four"
                  >
                    <div className="single__detail-features-review ">
                      <div className="comment-wrapper">
                        <div className="img-wrapper">
                          <img
                            className="img-fluid rounded-circle"
                            src={person_img}
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="info-wrapper">
                          <h6 className="mt-0">Jhon doe</h6>
                          <span className="mb-3">Mei 13, 2020</span>
                          <ul className="list-row d-flex">
                            <li className="list-row-item">
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </li>
                            <li className="list-row-item">3/5</li>
                          </ul>
                          <p>
                            Cras sit amet nibh libero, in gravida nulla. Nulla
                            vel metus scelerisque ante sollicitudin. Cras purus
                            odio, vestibulum in vulputate at, tempus viverra
                            turpis. Fusce condimentum nunc ac nisi vulputate
                            fringilla. Donec lacinia congue felis in faucibus.
                          </p>
                          <div className="comment-wrapper">
                            <div className="img-wrapper">
                              <img
                                className="img-fluid rounded-circle"
                                src={person_img}
                                alt=""
                                loading="lazy"
                              />
                            </div>
                            <div className="info-wrapper">
                              <h6 className="mt-0">Jhon doe</h6>
                              <span className="mb-3">Mei 13, 2020</span>
                              <ul className="list-row d-flex">
                                <li className="list-row-item">
                                  <i className="fa fa-star selected"></i>
                                  <i className="fa fa-star selected"></i>
                                  <i className="fa fa-star selected"></i>
                                  <i className="fa fa-star"></i>
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="list-row-item">3/5</li>
                              </ul>
                              <p>
                                Cras sit amet nibh libero, in gravida nulla.
                                Nulla vel metus scelerisque ante sollicitudin.
                                Cras purus odio, vestibulum in vulputate at,
                                tempus viverra turpis. Fusce condimentum nunc ac
                                nisi vulputate fringilla. Donec lacinia congue
                                felis in faucibus.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="comment-wrapper">
                        <div className="img-wrapper">
                          <img
                            className="img-fluid rounded-circle"
                            src={person_img}
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="info-wrapper">
                          <h6 className="mt-0">Jhon doe</h6>
                          <span className="mb-3">Mei 13, 2020</span>
                          <ul className="list-row d-flex">
                            <li className="list-row-item">
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </li>
                            <li className="list-row-item">3/5</li>
                          </ul>
                          <p>
                            Cras sit amet nibh libero, in gravida nulla. Nulla
                            vel metus scelerisque ante sollicitudin. Cras purus
                            odio, vestibulum in vulputate at, tempus viverra
                            turpis. Fusce condimentum nunc ac nisi vulputate
                            fringilla. Donec lacinia congue felis in faucibus.
                          </p>
                        </div>
                      </div>
                      <div className="comment-wrapper">
                        <div className="img-wrapper">
                          <img
                            className="img-fluid rounded-circle"
                            src={person_img}
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div className="info-wrapper">
                          <h6 className="mt-0">Jhon doe</h6>
                          <span className="mb-3">Mei 13, 2020</span>
                          <ul className="list-row d-flex">
                            <li className="list-row-item">
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </li>
                            <li className="list-row-item">3/5</li>
                          </ul>
                          <p>
                            Cras sit amet nibh libero, in gravida nulla. Nulla
                            vel metus scelerisque ante sollicitudin. Cras purus
                            odio, vestibulum in vulputate at, tempus viverra
                            turpis. Fusce condimentum nunc ac nisi vulputate
                            fringilla. Donec lacinia congue felis in faucibus.
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <p className="mb-2">Your rating for this listing:</p>
                          <ul className="list-row">
                            <li className="list-row-item">
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star selected"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </li>
                            <li className="list-row-item">3/5</li>
                          </ul>
                          <div className="form-group">
                            <label>Your Name</label>
                            <input
                              type="text"
                              className="custom-input"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>What's your Email?</label>
                            <input
                              type="email"
                              className="custom-input"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Subject</label>
                            <input
                              type="text"
                              className="custom-input"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Your message</label>
                            <textarea
                              className="custom-input"
                              rows="4"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="btn-box wd-fit">
                        <BtnComponent
                          value={"Submit review"}
                          type={"submit"}
                          icon={<i className="fa fa-paper-plane ml-2"></i>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <AgentSidebar />
            </div>
          </div>
        </div>
      </section>
      <Cta />
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default AgentDetailsPage;
