import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";
const initialState={
    allCities:[],
    popularCities:[],
    error:null,
    loading:true
}

export const GetAllCities = createAsyncThunk("city/all",async(queryString,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/cities')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
export const GetPopularCities = createAsyncThunk("city/popular",async(queryString,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/popular-cities')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
const CitySlice = createSlice({
    name:"citySlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllCities.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllCities.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allCities =action?.payload?.data
        })
        .addCase(GetAllCities.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.data
            state.allCities=action?.payload?.data
        })
        // popular cities
        .addCase(GetPopularCities.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetPopularCities.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.popularCities =action?.payload?.data
        })
        .addCase(GetPopularCities.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.data
            state.popularCities=action?.payload?.data
        })
        
    }
})
export const cityReducer = CitySlice.reducer
export const cityActions = CitySlice.actions