import React from "react";
import ScrollToTopBtn from "../../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Footer from "../../../components/GeneralSections/Footer/Footer";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import AgencyCard from "../../../components/Agency/AgencyCard/AgencyCard";
import AgencyCardSkeleton from "../../../components/Agency/AgencyCardSkeleton/AgencyCardSkeleton";
import CustomPagination from "../../../components/Utility/CustomPagination/CustomPagination";
import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection"
import { useTranslation } from "react-i18next";
import GetAllAgenciesHook from "../../../CustomHooks/AgencyHook/GetAllAgenciesHook";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const AgencyPage = () => {
  const [loading,agencies,onPressPaginate] = GetAllAgenciesHook();
  const [t] = useTranslation()
  return (
    <div className="agency-page page">
        <CustomHelmet
      title={t("SEO.meta.title")}
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <InternalHeader agencyActive={"agencyActive"}/>
      <BreadcrumbSection title={t("agencyPage.title")} />
      <section>
        <div className="profile__agency">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="row projects-wrapper no-gutters min-h-100">
                  {loading === false ? (
                    agencies?.map((item) => {
                      return (
                        <div className="col-md-6 col-lg-4 my-3" key={item?.id}>
                          <AgencyCard item={item} />
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <AgencyCardSkeleton />
                      <AgencyCardSkeleton />
                      <AgencyCardSkeleton />
                      <AgencyCardSkeleton />
                      <AgencyCardSkeleton />
                      <AgencyCardSkeleton />
                    </>
                  )}
                </div>
                <div className="pagination-content d-flex align-items-center justify-content-center">
                  <CustomPagination
                    totalPages={agencies?.meta?.last_page}
                    currentPage={agencies?.meta?.current_page}
                    onChange={onPressPaginate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default AgencyPage;
