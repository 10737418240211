const FormatNumberHook = (num) => {
  const formatNumber = (num) => {
    // Check if the number is greater than 999
    if (num >= 1000) {
      // Convert the number to a string
      let numStr = num.toString();

      // Initialize an empty string to store the formatted number
      let formattedNum = "";

      // Iterate through the string from the end
      for (let i = numStr.length - 1; i >= 0; i--) {
        // Add the current digit to the formattedNum string
        formattedNum = numStr[i] + formattedNum;

        // Add a comma every three digits
        if ((numStr.length - i) % 3 === 0 && i !== 0) {
          formattedNum = "," + formattedNum;
        }
      }

      return formattedNum;
    } else {
      // If the number is less than 1000, return it as is
      return num;
    }
  };
  return [formatNumber];
};

export default FormatNumberHook;
