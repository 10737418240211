import React from "react";
import "./ServiceCard.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const ServiceCard = ({ item }) => {
  const Navigate = useNavigate();
  const lang = Cookies.get("i18next");
  return (
    <div className="team-member-card wrap-agent service-card radius-10 overflow-hidden">
      <div className="team-member">
        <div className="team-img service-img overflow-hidden">
          <Swiper
            style={{
              "--swiper-navigation-color": "white",
              "--swiper-navigation-size": "25px",
            }}
            navigation={true}
            modules={[Navigation]}
            className="service-card-swiper w-100 h-100"
          >
            {item?.images?.map((img, i) => {
              return (
                <SwiperSlide
                  key={i}
                  onClick={() => Navigate(`/service-details-page/:${item?.id}`)}
                >
                  <div className="img-content position-relative overflow-hidden">
                    <img
                      loading="lazy"
                      src={img?.image}
                      alt="unit-img"
                      className="w-100 h-100 img-transition"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="team-title p-2">
          <h5 className="text-capitalize fw-bold color-primary ellipses mx-auto">
            {lang === "en"
              ? item?.data_trans
                ? item?.data_trans[1]?.title_en
                : null
              : item?.data_trans
              ? item?.data_trans[0]?.title_ar
              : null}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
