import { Skeleton } from '@mui/material'
import React from 'react'

const ProjectCardSkeleton = () => {
  return (
    <div className='project-card-skeleton my-2'>
    <div className='desktop d-none d-sm-block'>
    <div className='d-flex'>
    <div className='card-skeleton-head mx-1'>
    <Skeleton variant='rounded' width={315} height={220}/>
   </div>
   <div className='right-section'>
   <div className='card-skeleton-body'>
    <Skeleton variant='rounded' width={250} height={20} sx={{margin:"5px 0"}}/>
    <Skeleton variant='rounded' width={250} height={20} sx={{margin:"5px 0"}}/>
   <div className='d-flex'>
    <Skeleton variant='rounded' width={50} height={10} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={50} height={10} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={50} height={10} sx={{margin:"5px"}}/>
    </div>
    <div className='d-flex'>
    <Skeleton variant='rounded' width={50} height={10} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={50} height={10} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={50} height={10} sx={{margin:"5px"}}/>
    </div>
    <div className='d-flex'>
    <Skeleton variant='rounded' width={70} height={20} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={70} height={20} sx={{margin:"5px"}}/>
    </div>
   </div>
    <div className='card-skeleton-footer d-flex align-items-center'>
    <div className='d-flex'>
    <Skeleton variant='rounded' width={40} height={40} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={40} height={40} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={40} height={40} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={40} height={40} sx={{margin:"5px"}}/>
    </div>
    </div>
   </div>
    </div>
    </div>
    <div className='mobile w-100 d-block d-sm-none'>
   <div className='card-skeleton-head'>
   <Skeleton variant='rounded'  height={250}/>
   </div>
   <div className='card-skeleton-body'>
    <Skeleton variant='rounded'  height={15} sx={{margin:"5px 0"}}/>
    <Skeleton variant='rounded'  height={15} sx={{margin:"5px 0"}}/>
   <div className='d-flex'>
   <Skeleton variant='rounded' width={30} height={10} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={30} height={10} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={30} height={10} sx={{margin:"5px"}}/>
    </div>
    <div className='d-flex'>
    <Skeleton variant='rounded' width={30} height={10} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={30} height={10} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={30} height={10} sx={{margin:"5px"}}/>
    </div>
    <div className='card-skeleton-footer d-flex align-items-center'>
    <div className='d-flex'>
    <Skeleton variant='rounded' width={20} height={20} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={20} height={20} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={20} height={20} sx={{margin:"5px"}}/>
    <Skeleton variant='rounded' width={20} height={20} sx={{margin:"5px"}}/>
    </div>
    </div>
   </div>
   </div>
    </div>
  )
}

export default ProjectCardSkeleton
