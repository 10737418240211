import { useState, useEffect } from "react";
import Cookies from "js-cookie";

const useLang = () => {
  const [lang, setLang] = useState(Cookies.get("i18next") || "en"); // Default to 'en' if cookie doesn't exist

  useEffect(() => {
    const handleLangChange = () => {
      const newLang = Cookies.get("i18next");
      if (newLang !== lang) {
        setLang(newLang);
      }
    };

    // Add a cookie change listener if your application allows for dynamic changes
    window.addEventListener("cookiechange", handleLangChange); // This requires custom implementation to trigger events

    return () => {
      window.removeEventListener("cookiechange", handleLangChange);
    };
  }, [lang]);

  const updateLang = (newLang) => {
    Cookies.set("i18next", newLang, { expires: 7 }); // Set cookie to expire in 7 days
    setLang(newLang);
  };

  return { lang, updateLang };
};

export default useLang;
