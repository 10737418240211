import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllGates } from "../../Redux/Redux-Toolkit/Slices/Gates/GatesSlice";
const GetAllGatesHook = () => {
  const [loading,setLoading] = useState(false);
  const [gates,setGates] = useState([]);
  const res = useSelector((state) => state.rootReducer.gatesReducer.allGates);
  const dispatch = useDispatch();
  const getData =async () => {
    setLoading(true);
    await dispatch(GetAllGates());
    setLoading(false);
  }
  useEffect(() => {
    const cachedGates = sessionStorage.getItem('myGates');
    if (cachedGates) {
      setGates(JSON.parse(cachedGates));
    } else {
      getData();
    }
  },[]);

  useEffect(() => {
    try{
      if (loading === false) {
        if (res?.data?.data) {
          setGates(res.data.data);
          sessionStorage.setItem("myGates",JSON.stringify(res.data.data))
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,gates];
};

export default GetAllGatesHook;
