import {createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const initialState ={
    lang:Cookies.get("i18next"),
}
const LangSlice = createSlice({
    // name of this slice 
    name:"LangSlice",
    // the initial state
    initialState:initialState,
    // create the reducers
    reducers:{
        changeLang(state){
            state.activeMainCard = !state.activeMainCard
        },
    }
})
// export the reducer of this slice
export const langReducer = LangSlice.reducer
// export the actions of this slice
export const {changeLang} = LangSlice.actions