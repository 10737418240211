import React from "react";
import {
  faArrowPointer,
  faCalendar,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import BtnComponent from "../Utility/BtnComponent/BtnComponent";
import { useTranslation } from "react-i18next";

const JobCard = ({ item }) => {
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();
  return (
    <div className="offer-card radius-10 overflow-hidden my-2 mx-auto border border-1">
      <div className="card-body txt-start p-4 border border-1">
        <h3 className="fw-bold ellipses color-sec">{item?.title}</h3>
        <p className="text-secondary fs-16">{item?.sub_title}</p>
        <div className="d-flex align-items-center justify-content-between">
          <p className="location text-capitalize text-secondary fs-14 fw-medium ellipses">
            <span className="color-primary">
              <FontAwesomeIcon icon={faLocationDot} />
            </span>
            <span className="mx-2">{item?.address}</span>
          </p>
          {/* <p className="location text-capitalize text-secondary fs-14 fw-medium ellipses">
            <span className="color-primary">
              <FontAwesomeIcon icon={faCalendar} />
            </span>
            <span className="mx-2">20-12-2024</span>
          </p> */}
        </div>
        <div className="d-flex justify-content-end">
          <Link to={`/job-details/${item?.id}`}>
            <BtnComponent
              st={"wd-fit my-3 fw-normal"}
              value={t("jobs_page.show_details")}
              icon={<FontAwesomeIcon icon={faArrowPointer} />}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
