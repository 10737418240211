import { Skeleton } from "@mui/material";
import React from "react";

const ServiceCardSkeleton = () => {
  return (
    <div className="service-card-skeleton">
      <Skeleton
        variant="rounded"
        width={300}
        height={250}
        sx={{ margin: "5px 0", maxWidth: "100%" }}
      />
      <Skeleton
        variant="rounded"
        width={300}
        height={20}
        sx={{ margin: "5px 0" }}
      />
      <Skeleton
        variant="rounded"
        width={300}
        height={20}
        sx={{ margin: "5px 0" }}
      />
    </div>
  );
};

export default ServiceCardSkeleton;
