import React from "react";
import "./ContactUsSidebar.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const ContactUsSidebar = ({ data }) => {
  const [t] = useTranslation();
  return (
    <div className="contact-wrapper box">
      <ul>
        <li className="contact-item py-3 py-md-3 my-1 rounded d-flex align-items-center">
          <span className="mx-3 pointer flex-shrink-0 transition bg-light-pri color-primary w-50px h-50px rounded-circle d-flex align-items-center justify-content-center">
            <FontAwesomeIcon icon={faLocationDot} />
          </span>
          <div className="txt-start fs-18">
            <h4 className="m-0 my-2 color-primary fw-bold">
              {t("contactUsPage.contactUsSidebar.address")}
            </h4>
            <p className="m-0 fs-14">{data?.address}</p>
          </div>
        </li>
        <li className="contact-item py-3 py-md-3 my-1 rounded d-flex align-items-center">
          <a
            href={`mailto:${data?.email}`}
            target="_blank"
            rel="noreferrer"
            className="mx-3 bg-light-pri pointer transition flex-shrink-0 color-primary w-50px h-50px rounded-circle d-flex align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
          <div className="txt-start fs-18">
            <h4 className="m-0 my-2  color-primary fw-bold">
              {t("contactUsPage.contactUsSidebar.email")}
            </h4>
            <p className="m-0 fs-14">{data?.email}</p>
          </div>
        </li>
        <li className="contact-item py-3 py-md-3 my-1 rounded d-flex align-items-center">
          <a
            href={`tel:+20${data?.phone}`}
            target="_blank"
            rel="noreferrer"
            className="mx-3 pointer transition flex-shrink-0 bg-light-pri color-primary w-50px h-50px rounded-circle d-flex align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faPhone} />
          </a>
          <div className="txt-start fs-18">
            <h4 className="m-0 my-2  color-primary fw-bold">
              {t("contactUsPage.contactUsSidebar.phone")}
            </h4>
            <p className="m-0 fs-14">{data?.phone}</p>
          </div>
        </li>
        <li className="contact-item py-3 py-md-3 my-1 rounded d-flex align-items-center">
          <a
            href={`https://wa.me/+20${data?.phone}`}
            target="_blank"
            rel="noreferrer"
            className="mx-3 pointer transition flex-shrink-0 bg-light-pri color-primary w-50px h-50px rounded-circle d-flex align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
          <div className="txt-start fs-18">
            <h4 className="m-0 my-2  color-primary fw-bold">
              {t("contactUsPage.contactUsSidebar.whatsapp")}
            </h4>
            <p className="m-0 fs-14">{data?.phone}</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ContactUsSidebar;
