// import { useSearchParams } from "react-router-dom";

// const AddSearchParamsHook = () => {

//   const [searchParams, setSearchParams] = useSearchParams();
//   const add_search_params = (key, value) => {
//     searchParams.set(key, value);
//     setSearchParams(searchParams);
//   };
//   return [add_search_params, searchParams, setSearchParams];
// };

// export default AddSearchParamsHook;

import { useSearchParams } from "react-router-dom";

const AddSearchParamsHook = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const add_search_params = (key, value) => {
    // Create a new instance of URLSearchParams
    const newSearchParams = new URLSearchParams(searchParams);

    // If value is undefined, do not set it
    if (value !== undefined) {
      newSearchParams.set(key, String(value)); // Ensure value is a string
    } else {
      newSearchParams.delete(key); // Optionally remove the key if value is undefined
    }

    setSearchParams(newSearchParams);
  };

  return [add_search_params, searchParams, setSearchParams];
};

export default AddSearchParamsHook;
