import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import MainNavbar from "../../Utility/Navbar/MainNavbar/MainNavbar";
import BtnComponent from "../../Utility/BtnComponent/BtnComponent";
import profile_img from "../../../images/anonymous.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faBell,
  faGlobe,
  faHeart,
  faOutdent,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { changeActive } from "../../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice";
import Cookies from "js-cookie";
// import OurInfoHook from "../../../CustomHooks/OurSettings/OurInfo/OurInfoHook";
import UserProfileHook from "../../../CustomHooks/User/UserProfileHook";
import UserNotificationsHook from "../../../CustomHooks/User/UserNotificationsHook";
import GetAllWishlistHook from "../../../CustomHooks/Wishlist/GetAllWishlistHook";
import GetNotificationsCountHook from "../../../CustomHooks/Notificatoins/GetNotificationsCountHook";
import { Skeleton } from "@mui/material";
import logo from "../../../images/shahid 3qar logo 2.png";
import ar from "../../../images/Ar.png";
import en from "../../../images/En.png";
import { useTranslation } from "react-i18next";
const Header = () => {
  const [showNots, setShowNots] = useState(false);
  // code for display home sidebar
  const dispatch = useDispatch();
  const handleClick = (e) => {
    dispatch(changeActive());
  };
  // const [data] = OurInfoHook();
  const [, , userData] = UserProfileHook();
  const [, loading, notData] = UserNotificationsHook();
  const [, wishlist] = GetAllWishlistHook();
  const [countLoading, count] = GetNotificationsCountHook();
  const { t, i18n } = useTranslation();
  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang);
  };
  // const lang = Cookies.get("i18next");
  const NotSkeleton = () => {
    return (
      <li className="nots-item">
        <div className="img-box">
          <Skeleton
            variant="circular"
            height={50}
            width={50}
            sx={{ margin: "0 5px" }}
          />
        </div>
        <div className="text">
          <Skeleton
            variant="h5"
            height={20}
            width={200}
            sx={{ margin: "3px 0px", width: "100%" }}
          />
          <Skeleton
            variant="h5"
            height={20}
            width={200}
            sx={{ margin: "3px 0px", width: "100%" }}
          />
        </div>
      </li>
    );
  };
  return (
    <header className="header position-relative">
      <div className="container-fluid">
        <div className="row">
          <div className="col-4 col-lg-2">
            <div className="logo-box h-100 d-flex align-items-center justify-content-start">
              <img
                className="company-logo"
                src={logo}
                alt="logo"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col d-none d-lg-block col-lg-8">
            <MainNavbar />
          </div>
          <div className="col-8  col-lg-2 right-btns-wrapper">
            <div className="btns-parent d-flex flex-nowrap justify-content-end px-2 h-100 navbar navbar-hover navbar-expand-lg navbar-soft navbar-transparent">
              <div className="auth-btns-wrapper main-navbar">
                <ul className="navbar-nav auth-btns flex-row d-flex align-items-center justify-content-end h-100">
                  <li className="nav-item dropdown d-none d-lg-block">
                    <span className="nav-link  text-capitalize">
                      <span className="mx-1">
                        <FontAwesomeIcon icon={faGlobe} />
                        {/* {lang==="en"?t("generalSections.navBar.en"):t("generalSections.navBar.ar")} */}
                      </span>
                    </span>
                    <ul className="dropdown-menu animate fade-up lang-dropdown txt-start">
                      <li
                        className="dropdown-item icon-arrow text-capitalize text-white fw-bold d-flex align-items-center justify-content-evenly flex-nowrap"
                        onClick={() => handleChangeLang("ar")}
                      >
                        <span> {t("generalSections.navBar.ar")}</span>
                        <span>
                          <img
                            className="ar-img"
                            src={ar}
                            alt="ar-img"
                            loading="lazy"
                          />
                        </span>
                      </li>
                      <li
                        className="dropdown-item icon-arrow text-capitalize text-white fw-bold d-flex align-items-center justify-content-evenly flex-nowrap"
                        onClick={() => handleChangeLang("en")}
                      >
                        <span> {t("generalSections.navBar.en")}</span>
                        <span>
                          <img
                            className="en-img"
                            src={en}
                            alt="en-img"
                            loading="lazy"
                          />
                        </span>
                      </li>
                    </ul>
                  </li>
                  {typeof Cookies.get("user") === "undefined" ? (
                    <li className="mx-2">
                      <Link to="/login-page">
                        <div className="btn-box">
                          <BtnComponent
                            value={t("generalSections.header.login")}
                            icon={<FontAwesomeIcon icon={faUser} />}
                          />
                        </div>
                      </Link>
                    </li>
                  ) : (
                    <>
                      <li
                        className="mx-2 text-white fw-bold fs-20 pointer position-relative"
                        onMouseOut={() => setShowNots(false)}
                        onMouseOver={() => setShowNots(true)}
                      >
                        {countLoading === false ? (
                          count > 0 ? (
                            <span className="count-badge fs-14 text-white bg-pri rounded-circle position-absolute d-flex align-items-center justify-content-center">
                              {count}
                            </span>
                          ) : null
                        ) : null}
                        <span>
                          <FontAwesomeIcon icon={faBell} />
                        </span>
                        <div
                          className={`nots-box txt-start z-10 rounded overflow-auto position-absolute transition ${
                            showNots ? "active" : ""
                          }`}
                        >
                          <ul>
                            {count > 0 ? (
                              loading === false ? (
                                notData?.map((item, i) => {
                                  return (
                                    item?.read_at === null && (
                                      <li
                                        className="nots-item border-bottom"
                                        key={i}
                                      >
                                        <Link to="/user-notifications-page">
                                          <div className="img-box">
                                            <img
                                              src={logo}
                                              alt="logo-img"
                                              loading="lazy"
                                            />
                                          </div>
                                        </Link>
                                        <div className="text">
                                          {item?.data?.item ? (
                                            <>
                                              <h5 className="fs-18 color-primary">
                                                {item.data.item.title}
                                              </h5>
                                              <p>{item.data.item.content}</p>
                                            </>
                                          ) : (
                                            <>
                                              <h5 className="fs-18 color-primary">
                                                {item?.data?.title}
                                              </h5>
                                              <p className="my-0">
                                                {item?.data?.message}
                                              </p>
                                              <p className="my-0">
                                                {item?.data?.product_title}
                                              </p>
                                            </>
                                          )}
                                        </div>
                                      </li>
                                    )
                                  );
                                })
                              ) : (
                                <>
                                  <NotSkeleton />
                                  <NotSkeleton />
                                  <NotSkeleton />
                                </>
                              )
                            ) : (
                              <div className="w-100 h-100 text-center px-2 py-4">
                                <p className="fs-22">
                                  {t("generalSections.header.noNotifications")}
                                </p>
                              </div>
                            )}
                          </ul>
                        </div>
                      </li>
                      <li className="mx-2 text-white fw-bold fs-20 pointer position-relative">
                        <span className="count-badge fs-14 text-white bg-pri rounded-circle position-absolute d-flex align-items-center justify-content-center">
                          {wishlist?.length || 0}
                        </span>
                        <Link to="/user-wishlist-page" className="text-white">
                          <FontAwesomeIcon icon={faHeart} />
                        </Link>
                      </li>
                      <li className="mx-2 profile-btn-wrapper">
                        <Link to="/user-profile-page">
                          <div className="img-box">
                            <img
                              className="h-100 w-100"
                              src={userData?.image || profile_img}
                              alt="profile-img"
                              loading="lazy"
                            />
                          </div>
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <div
                className="toggle-btn-sidebar d-block d-lg-none"
                onClick={handleClick}
              >
                <div className="btn-box h-100 wd-fit d-flex align-items-center justify-content-center">
                  <BtnComponent
                    type={"button"}
                    icon={<FontAwesomeIcon icon={faBarsStaggered} />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
