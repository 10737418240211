import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";
import { usePostData } from "../../../../Hooks/PostDataHook";

const initialState = {
  allJobs: [],
  JobDetails: [],
  jobApplyRes: [],
  error: null,
  loading: true,
};

export const GetAllJobs = createAsyncThunk(
  "allJobs",
  async (page, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(`/jobs?per_page=20&page=${page}`);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GetJobDetails = createAsyncThunk(
  "jobDetails",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(`/jobs/${id}`);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const postJobApply = createAsyncThunk(
  "postJobApply",
  async (params, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await usePostData(
        `/jobs/${params?.job_id}/job_register`,
        params
      );
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const JobsSlice = createSlice({
  name: "JobsSlice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(GetAllJobs.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAllJobs.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allJobs = action?.payload?.data;
      })
      .addCase(GetAllJobs.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.data;
        state.allJobs = action?.payload?.data;
      })
      .addCase(GetJobDetails.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetJobDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.JobDetails = action?.payload;
      })
      .addCase(GetJobDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.JobDetails = action?.payload;
      })
      .addCase(postJobApply.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postJobApply.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.jobApplyRes = action?.payload;
      })
      .addCase(postJobApply.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.jobApplyRes = action?.payload;
      });
  },
});
export const jobsReducer = JobsSlice.reducer;
export const JobsActions = JobsSlice.actions;
