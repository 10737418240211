import React from "react";
import './Breadcrumb.css'
import { Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
const BreadcrumbSection = ({title}) => {
  const [t] = useTranslation()
  return (
      <section className="section__breadcrumb p-0">
        <div className="overlay"></div>
        <div className="child position-relative d-flex align-items-center justify-content-center">
            <div className="text-center">
              <h2 className="text-capitalize text-white ">{title}</h2>
              <ul className="list-inline ">
                <li className="list-inline-item  mx-1">
                  <Link to={"/"} className="text-white">
                    {t("generalSections.breadcrumbSection.home")}
                  </Link>
                </li>
                <li className="list-inline-item mx-1">
                  <Link to={"/listing-page"} className="text-white">
                  {t("generalSections.breadcrumbSection.property")}
                  </Link>
                </li>
                <li className="list-inline-item mx-1">
                  <span className="text-white">
                    {title}
                  </span>
                </li>
              </ul>
            </div>
        </div>
      </section>
  );
};

export default BreadcrumbSection;
