import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUnitDetails } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";

const GetUnitDetailsHook = (id) => {
  const [loading, setLoading] = useState(false);
  const [unitDetails, setUnitDetails] = useState([]);
  const res = useSelector(
    (state) => state.rootReducer.unitsReducer.unitDetails
  );
  const dispatch = useDispatch();

  const getData = async () => {
    setLoading(true);
    await dispatch(GetUnitDetails(id));
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    if (res?.status === 200) {
      setUnitDetails(res.data.data);
    }
  }, [res]);

  return [loading, unitDetails];
};

export default GetUnitDetailsHook;
