import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetJobDetails } from "../../Redux/Redux-Toolkit/Slices/Jobs/JobsSlice";

const GetJobDetailsHook = (id) => {
  const [loading, setLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState([]);

  const res = useSelector((state) => state.rootReducer.jobsReducer.JobDetails);

  const dispatch = useDispatch();

  const getData = async () => {
    setLoading(true);
    await dispatch(GetJobDetails(id));
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    if (res?.status === 200) {
      setJobDetails(res?.data?.data);
    }
  }, [res]);

  return [loading, jobDetails];
};

export default GetJobDetailsHook;
