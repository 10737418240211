import {createSlice } from "@reduxjs/toolkit";

const initialState ={
    activeMainCard:false,
    activeCard:false,
    activeDetails:false
}
const ShareSlice = createSlice({
    // name of this slice 
    name:"ShareSlice",
    // the initial state
    initialState:initialState,
    // create the reducers
    reducers:{
        changeShareMainCardActive(state){
            state.activeMainCard = !state.activeMainCard
        },
        changeShareCardActive(state){
            state.activeCard = !state.activeCard
        },
        changeShareDetailsActive(state){
            state.activeDetails = !state.activeDetails
        }
    }
})
// export the reducer of this slice
export const shareReducer = ShareSlice.reducer
// export the actions of this slice
export const {changeShareCardActive,changeShareDetailsActive,changeShareMainCardActive} = ShareSlice.actions