import React from "react";
import "./SingleDetailInfo.css";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const SingleDetailInfo = ({ unitDetails }) => {
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();
  return (
    <div className="single__detail-features single-detail-info txt-start">
      <h5 className="text-capitalize detail-heading fw-bold fs-22 color-primary">
        {t("singleDetailsPage.singleDetailsInfo.title")}
      </h5>
      <table>
        <tr>
          <td>{t("singleDetailsPage.singleDetailsInfo.propertyCode")}</td>
          <td>{unitDetails?.code}</td>
        </tr>
        <tr>
          <td>{t("singleDetailsPage.singleDetailsInfo.type")}</td>
          <td>{unitDetails?.type}</td>
        </tr>
        <tr>
          <td>{t("singleDetailsPage.singleDetailsInfo.location")}</td>
          <td>{unitDetails?.location}</td>
        </tr>
        <tr>
          <td>{t("singleDetailsPage.singleDetailsInfo.serviceCharges")}</td>
          <td> {unitDetails?.service_charges}</td>
        </tr>
        <tr>
          <td>{t("singleDetailsPage.singleDetailsInfo.floor")}</td>
          <td>{unitDetails?.floor}</td>
        </tr>
        <tr>
          <td>{t("singleDetailsPage.singleDetailsInfo.category")}</td>
          <td>
            {lang === "en"
              ? unitDetails?.category?.data_trans
                ? unitDetails?.category?.data_trans[1]?.title_en
                : "...."
              : unitDetails?.category?.data_trans
              ? unitDetails?.category?.data_trans[0]?.title_ar
              : "...."}
          </td>
        </tr>
        <tr>
          <td>{t("singleDetailsPage.singleDetailsInfo.size")}</td>
          <td>{unitDetails?.size}</td>
        </tr>
        <tr>
          <td>{t("singleDetailsPage.singleDetailsInfo.rooms")}</td>
          <td>{unitDetails?.rooms}</td>
        </tr>
        <tr>
          <td>{t("singleDetailsPage.singleDetailsInfo.bathrooms")}</td>
          <td>{unitDetails?.bathroom}</td>
        </tr>
        <tr>
          <td>{t("singleDetailsPage.singleDetailsInfo.paying")}</td>
          <td>{unitDetails?.paying}</td>
        </tr>
      </table>

      {/* <div className="property__detail-info">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <ul className="property__detail-info-list list-unstyled">
              <li>
                <span>
                  <span className="mx-2 color-primary">
                    <FontAwesomeIcon icon={faFileCode} />
                  </span>
                  <b>
                    {t("singleDetailsPage.singleDetailsInfo.propertyCode")}:
                  </b>
                </span>
                <span className="mx-2">{unitDetails?.code}</span>
              </li>
              <li>
                <span>
                  <span className="mx-2 color-primary">
                    <FontAwesomeIcon icon={faSackDollar} />
                  </span>
                  <b>{t("singleDetailsPage.singleDetailsInfo.paying")} :</b>
                </span>
                <span className="mx-2">{unitDetails?.paying}</span>
              </li>
              <li>
                <span>
                  <span className="mx-2 color-primary">
                    <FontAwesomeIcon icon={faT} />
                  </span>
                  <b>{t("singleDetailsPage.singleDetailsInfo.type")}:</b>
                </span>
                <span className="mx-2">{unitDetails?.type}</span>
              </li>
              <li>
                <span>
                  <span className="mx-2 color-primary">
                    <FontAwesomeIcon icon={faMap} />
                  </span>
                  <b>{t("singleDetailsPage.singleDetailsInfo.location")}:</b>
                </span>
                <span className="mx-2">{unitDetails?.location}</span>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-6">
            <ul className="property__detail-info-list list-unstyled">
              <li>
                <span>
                  <span className="mx-2 color-primary">
                    <FontAwesomeIcon icon={faMoneyBill} />
                  </span>
                  <b>
                    {t("singleDetailsPage.singleDetailsInfo.serviceCharges")}:
                  </b>
                </span>
                <span className="mx-2">{unitDetails?.service_charges}</span>
              </li>
              <li>
                <span>
                  <span className="mx-2 color-primary">
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  <b>{t("singleDetailsPage.singleDetailsInfo.floor")}:</b>
                </span>
                <span className="mx-2">{unitDetails?.floor}</span>
              </li>
              <li>
                <span>
                  <span className="mx-2 color-primary">
                    <FontAwesomeIcon icon={faLayerGroup} />
                  </span>
                  <b>{t("singleDetailsPage.singleDetailsInfo.category")}:</b>
                </span>
                <span className="mx-2">
                  {lang === "en"
                    ? unitDetails?.category?.data_trans
                      ? unitDetails?.category?.data_trans[1]?.title_en
                      : "...."
                    : unitDetails?.category?.data_trans
                    ? unitDetails?.category?.data_trans[0]?.title_ar
                    : "...."}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SingleDetailInfo;
