import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";

const initialState = {
  allUnits: [],
  installmentUnits: [],
  financeUnits: [],
  lands: [],
  landDetails: [],
  premiumUnits: [],
  similarUnits: [],
  unitDetails: [],
  error: null,
  loading: true,
};

export const GetAllUnits = createAsyncThunk(
  "allUnits",
  async (queryString, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(`/units?per_page=20&${queryString}`);
      return res;
    } catch (error) {
      // console.clear()
      return rejectWithValue(error);
    }
  }
);

export const GetInstallmentUnits = createAsyncThunk(
  "installmentUnits",
  async (queryString, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(
        `/units?per_page=20&paying=Installment&${queryString}`
      );
      return res;
    } catch (error) {
      // console.clear()
      return rejectWithValue(error);
    }
  }
);
export const GetFinanceUnits = createAsyncThunk(
  "FinanceUnits",
  async (queryString, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(
        `/units?per_page=20&finance=1&${queryString}`
      );
      return res;
    } catch (error) {
      // console.clear()
      return rejectWithValue(error);
    }
  }
);

export const GetAllLands = createAsyncThunk(
  "lands",
  async (queryString, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(`/lands?per_page=${16}&${queryString}`);
      return res;
    } catch (error) {
      // console.clear()
      return rejectWithValue(error);
    }
  }
);

export const GetLandDetails = createAsyncThunk(
  "landDetails",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(`/lands/${id}`);
      return res;
    } catch (error) {
      // console.clear()
      return rejectWithValue(error);
    }
  }
);

export const GetPremiumUnits = createAsyncThunk(
  "premiumUnits",
  async (queryString, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(
        `/units?per_page=16&primum=1&${queryString}`
      );
      return res;
    } catch (error) {
      // console.clear()
      return rejectWithValue(error);
    }
  }
);

export const GetAllSimilarUnits = createAsyncThunk(
  "allSimilarUnits",
  async (queryString, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(`/units?per_page=${12}&${queryString}`);
      return res;
    } catch (error) {
      // console.clear()
      return rejectWithValue(error);
    }
  }
);

export const GetUnitDetails = createAsyncThunk(
  "unitDetails",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await useGetData(`/units/${id}`);
      return res;
    } catch (error) {
      // console.clear()
      return rejectWithValue(error);
    }
  }
);

const UnitsSlice = createSlice({
  name: "unitsSlice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(GetAllUnits.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAllUnits.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allUnits = action?.payload?.data;
      })
      .addCase(GetAllUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.response;
        state.allUnits = action?.payload?.response;
      })
      // installment units
      .addCase(GetInstallmentUnits.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetInstallmentUnits.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.installmentUnits = action?.payload?.data;
      })
      .addCase(GetInstallmentUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.response;
        state.installmentUnits = action?.payload?.response;
      })
      // finance units
      .addCase(GetFinanceUnits.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetFinanceUnits.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.financeUnits = action?.payload?.data;
      })
      .addCase(GetFinanceUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.response;
        state.financeUnits = action?.payload?.response;
      })
      // lands units
      .addCase(GetAllLands.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAllLands.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.lands = action?.payload?.data;
      })
      .addCase(GetAllLands.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.response;
        state.lands = action?.payload?.response;
      })
      // land details units
      .addCase(GetLandDetails.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetLandDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.landDetails = action?.payload?.data;
      })
      .addCase(GetLandDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.response;
        state.landDetails = action?.payload?.response;
      })
      // premium units
      .addCase(GetPremiumUnits.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPremiumUnits.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.premiumUnits = action?.payload?.data;
      })
      .addCase(GetPremiumUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.response;
        state.premiumUnits = action?.payload?.response;
      })
      // similar
      .addCase(GetAllSimilarUnits.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAllSimilarUnits.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.similarUnits = action?.payload?.data;
      })
      .addCase(GetAllSimilarUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.data;
        state.similarUnits = action?.payload?.data;
      })
      // unit details
      .addCase(GetUnitDetails.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetUnitDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.unitDetails = action?.payload;
      })
      .addCase(GetUnitDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.unitDetails = action?.payload;
      });
  },
});

export const unitsReducer = UnitsSlice.reducer;
export const unitsActions = UnitsSlice.actions;
