import { useEffect, useState } from 'react'
import Notify from '../UseNotification/UseNotification'
import AddToWishlistHook from '../Wishlist/AddToWishlistHook'
import DeleteFromWishlistHook from '../Wishlist/DeleteFromWishlistHook'
import Cookies from "js-cookie"
import { useNavigate } from 'react-router-dom'
const CardHook = (item,favItem) => {
    const [isFav,setIsFav] = useState(false)
    const Navigate = useNavigate()
    const [wishlistCount,setWishlistCount] = useState(item?.count_wishlist)
    const [addToWishlistFn,add_loading,add_res] = AddToWishlistHook()
    const[deleteFromWishlistFn,delete_loading,delete_res] = DeleteFromWishlistHook()

    let isFavItem = favItem?.some((i)=> i === item?.id)
    
    useEffect(()=>{
      setIsFav(favItem?.some((i)=>i === item?.id))
    },[favItem])
  
  
  const handleClickWishlist =async(id)=>{
      if(Cookies.get("user_token") !== undefined){
        if(isFav === true){
            await deleteFromWishlistFn(id)
          }else{
            await addToWishlistFn(id)
          }
      }else{
        Notify("please login first","warn")
        Navigate("/login-page")
      }
  }
useEffect(()=>{
  if(delete_loading===false){
    if(delete_res?.status===200){
      setIsFav(false)
      setWishlistCount(wishlistCount===0?wishlistCount:wishlistCount - 1)
    }else{return}
  }else{return}
},[delete_loading])

useEffect(()=>{
  if(add_loading===false){
    if(add_res?.status === 200){
      setIsFav(true)
      setWishlistCount(wishlistCount + 1)
    }else{return}
  }else{return}
},[add_loading])

    return [handleClickWishlist,isFav,wishlistCount]
}

export default CardHook
