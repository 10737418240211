import React from "react";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import anonymous from "../../images/anonymous-image.png";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const LandCard = ({ item }) => {
  const lang = Cookies.get("i18next");

  return (
    <div className="offer-card radius-10 overflow-hidden my-2 mx-auto border border-1">
      <div className="card-head position-relative pointer hover-scale-img h-250px">
        <Link to={`/land/${item?.id}`}>
          <img
            src={item?.image || anonymous}
            alt="offer-img"
            loading="lazy"
            className="h-250px w-100 transition"
          />
        </Link>
      </div>
      <div className="card-body txt-start p-2 border border-1">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="fw-bold m-0 ellipses">
            {lang === "en"
              ? item?.data_trans
                ? item?.data_trans?.[1]?.title_en
                : null
              : item?.data_trans
              ? item?.data_trans?.[0]?.title_ar
              : null}
          </h6>
          <ul className="list-row my-1 contacts">
            <li className="list-row-item">
              <a
                href={`https://wa.me/+2${item?.user?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social whatsapp radius-50 overflow-hidden"
              >
                <i className="fa fa-whatsapp"></i>
              </a>
            </li>
            <li className="list-row-item">
              <a
                href={`tel:+2${item?.user?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social twitter radius-50 overflow-hidden"
              >
                <i className="fa fa-phone"></i>
              </a>
            </li>
          </ul>
        </div>
        <p className="location text-capitalize m-0 text-secondary fs-14 fw-medium ellipses">
          <span className=" text-secondary">
            <FontAwesomeIcon icon={faLocationDot} />
          </span>
          <span className="mx-2">{item?.address}</span>
        </p>
      </div>
    </div>
  );
};

export default LandCard;
