import React from "react";
import { useTranslation } from "react-i18next";

const AuthTitle = ({ title }) => {
  const [t] = useTranslation();
  return (
    <div className="my-2">
      <p className="color-sec fw-bold fs-22 m-0">{t("authPages.welcome")}</p>
      <h2 className="fw-bold primary-color text-capitalize">{title}</h2>
    </div>
  );
};

export default AuthTitle;
