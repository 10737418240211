import React from "react";
import NotFound from "../Utility/NotFound/NotFound";
import CustomPagination from "../Utility/CustomPagination/CustomPagination";
import ServiceCardSkeleton from "../Services/ServiceCardSkeleton/ServiceCardSkeleton";
import LandCard from "./LandCard";
import GetAllLandsHook from "../../CustomHooks/Lands/GetAllLandsHook";

const LandsListContent = () => {
  const [loading, lands, unitsCount, meta, , onPressPaginate] =
    GetAllLandsHook();
  const landsSkeleton = Array.from({ length: 12 }, (_, index) => index + 1);

  return (
    <div className="list-content my-5 ">
      <div className="row units-wrapper min-h-100">
        {loading === false ? (
          unitsCount > 0 ? (
            lands?.map((item) => (
              <div className="col-md-6 col-xl-3" key={item?.id}>
                <LandCard item={item} />
              </div>
            ))
          ) : (
            <NotFound />
          )
        ) : (
          landsSkeleton.map((_, i) => (
            <div className="col-md-6 col-xl-3" key={i}>
              <ServiceCardSkeleton />
            </div>
          ))
        )}
      </div>
      <div className="pagination-content d-flex align-items-center justify-content-center">
        <CustomPagination
          totalPages={meta?.last_page}
          currentPage={meta?.current_page}
          onChange={onPressPaginate}
        />
      </div>
    </div>
  );
};

export default LandsListContent;
