import React from "react";
import { useTranslation } from "react-i18next";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import ScrollToTopBtn from "../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Footer from "../../components/GeneralSections/Footer/Footer";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import GetNewDetailsHook from "../../CustomHooks/News/GetNewDetailsHook";
import { useParams } from "react-router-dom";
import ImagesSlider from "../../components/SingleDetails/ImagesSlider/ImagesSlider";
import HomeSectionSkeleton from "../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import Cookies from "js-cookie";
import DisplayWordpadContent from "../../components/Utility/DisplayWordpadContent/DisplayWordpadContent";
import GetAllNewsHook from "../../CustomHooks/News/GetAllNewsHook";
import NewsCard from "../../components/News/NewsCard/NewsCard";
import NotFound from "../../components/Utility/NotFound/NotFound";
import WantToSell from "../../components/Home/WantToSell/WantToSell";
const NewsDetailsPage = () => {
  const [t] = useTranslation();
  const lang = Cookies.get("i18next");
  const { id } = useParams();
  const [loading, newDetails] = GetNewDetailsHook(id);
  const [loadingAll, news, newsCount] = GetAllNewsHook();

  return (
    <div className="news-details-page page">
      <CustomHelmet
        title={"News Details"}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader
        breadcrumbTitle={"News Details"}
        newsActive={"newsActive"}
      />
      <section>
        <div className="container">
          <div className="row min-h-100">
            <div className="col-xl-8">
              {loading === false ? (
                newDetails?.images?.length > 0 ? (
                  <ImagesSlider items={newDetails.images} />
                ) : (
                  <img
                    src={newDetails?.images?.[0]?.image}
                    alt="offer-img"
                    loading="lazy"
                    className="w-100 radius-10 h-500px"
                  />
                )
              ) : (
                <HomeSectionSkeleton />
              )}
              <div className="txt-start my-5">
                <h2 className="text-capitalize color-sec2 fw-bold">
                  {lang === "en"
                    ? newDetails?.data_trans?.[0]?.title_ar
                    : newDetails?.data_trans?.[1]?.title_en}
                </h2>
                <p className="color-primary fw-bold m-0">
                  {lang === "en"
                    ? newDetails?.categories?.[0]?.data_trans?.[0]?.title_ar
                    : newDetails?.categories?.[0].data_trans?.[1]?.title_en}
                </p>
                <DisplayWordpadContent
                  content={
                    lang === "en"
                      ? newDetails?.data_trans?.[0]?.description_ar
                      : newDetails?.data_trans?.[1]?.description_en
                  }
                />
              </div>
            </div>
            <div className="col-xl-4">
              {loadingAll === false ? (
                newsCount > 0 ? (
                  news?.map((item) => {
                    return <NewsCard item={item} />;
                  })
                ) : (
                  <NotFound />
                )
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <WantToSell />
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default NewsDetailsPage;
