import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";

const initialState = {
    allNews:[],
    newsDetails:[],
    error:null,
    loading:true
}
export const GetAllNews = createAsyncThunk("allNews",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/news')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
export const GetNewsDetails = createAsyncThunk("newsDetails",async(id,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData(`/news/${id}`)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)

const NewsSlice = createSlice({
    name:"NewsSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllNews.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllNews.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allNews =action?.payload
        })
        .addCase(GetAllNews.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.allNews =action?.payload
        })
        // agency details
        .addCase(GetNewsDetails.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetNewsDetails.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.newsDetails =action?.payload
        })
        .addCase(GetNewsDetails.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.newsDetails =action?.payload
        })
    }
})
export const newsReducer = NewsSlice.reducer
export const newsActions = NewsSlice.actions