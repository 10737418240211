import baseUrl from "../Api/baseUrl";
import Cookies from 'js-cookie'
// this is custom hook called useGetData like useEffect and other hooks

// if we need to post data without images
const usePostData = async(url,params)=>{
    // url for our url from api
    // params if we need to put a params 
    // params => {key:"value"}
    const res = await baseUrl.post(url,params)
    return res
}
const usePostDataToken = async(url,params)=>{
    // url for our url from api
    // params if we need to put a params 
    // params => {key:"value"}
    const config={
        headers:{"Authorization":`Bearer ${Cookies.get("user_token")}`}
    }
    if(Cookies.get("user_token")){
        const res = await baseUrl.post(url,params,config)
        return res
    }else{return}
}

// if we need to post data with images
const usePostDataWithImage = async(url,params)=>{
    const config ={
        headers:{
        "Content-type":"multipart/form-data",
        "Authorization":`Bearer ${Cookies.get("user_token")}`}
    }
    if(Cookies.get("user_token")){
        const res = await baseUrl.post(url,params,config)
        return res
    }else{return}
}

export {usePostData,usePostDataWithImage,usePostDataToken};