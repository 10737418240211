import React, { useState } from "react";
import "../Navbar.css";
import BtnComponent from "../../../Utility/BtnComponent/BtnComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered, faGlobe } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../../Utility/Sidebar/Sidebar";
import profile_img from "../../../../images/anonymous.png";
import logo from "../../../../images/logo.jpg";
import { Link } from "react-router-dom";
import InternalNavbarHook from "../../../../CustomHooks/Navbar/InternalNavbarHook";
import { useTranslation } from "react-i18next";
import ar from "../../../../images/Ar.png";
import en from "../../../../images/En.png";

const InternalNavbar = ({
  installment,
  homeActive,
  listsActive,
  aboutUsActive,
  contactUsActive,
  agencyActive,
  servicesActive,
  installmentActive,
  landsActive,
  offersActive,
  financeActive,
}) => {
  const [
    dataI,
    data,
    categories,
    categories_loading,
    handleCategoryClick,
    lang,
    ,
    handleClick,
    user,
  ] = InternalNavbarHook();
  const [sticky, setSticky] = useState("");

  window.onscroll = () => {
    if (window.scrollY > 50) {
      setSticky("sticky");
    } else {
      setSticky("");
    }
  };

  const { t, i18n } = useTranslation();
  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <nav className="internal-navbar navbar navbar-hover navbar-expand-lg navbar-soft">
      <div className={`container-fluid ${sticky}`}>
        <ul className="navbar-nav d-flex align-items-center flex-row gap-2">
          <li>
            <Link to={"/"} className="navbar-brand">
              <img src={logo} loading="lazy" alt="logo" className="img-fluid" />
            </Link>
          </li>
          <li className="nav-item dropdown color-primary  w-35px h-35px">
            <span className="text-capitalize w-35px h-35px rounded-circle p-0 bg-light-gray fit-flex">
              <FontAwesomeIcon icon={faGlobe} />
            </span>
            <ul className="dropdown-menu animate fade-up lang-dropdown txt-start text-black">
              <li
                className="dropdown-item icon-arrow text-capitalize fw-bold d-flex align-items-center justify-content-evenly flex-nowrap"
                onClick={() => handleChangeLang("ar")}
              >
                <span> {t("generalSections.navBar.ar")}</span>
                <span>
                  <img
                    className="ar-img"
                    src={ar}
                    alt="ar-img"
                    loading="lazy"
                  />
                </span>
              </li>
              <li
                className="dropdown-item icon-arrow text-capitalize fw-bold d-flex align-items-center justify-content-evenly flex-nowrap"
                onClick={() => handleChangeLang("en")}
              >
                <span> {t("generalSections.navBar.en")}</span>
                <span>
                  <img
                    className="en-img"
                    src={en}
                    alt="en-img"
                    loading="lazy"
                  />
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <div className="control-btns d-flex align-items-center ">
          <div className="auth-content d-block d-sm-none">
            {typeof user === "undefined" ? (
              <div className="login-btn mx-2">
                <Link to={"/login-page"}>
                  <BtnComponent value={"login"} />
                </Link>
              </div>
            ) : (
              <div className="profile-btn profile-btn-wrapper mx-2">
                <Link to="/user-profile-page">
                  <div className="img-box">
                    <img
                      className="h-100 w-100"
                      src={data?.image || profile_img}
                      alt="profile-img"
                      loading="lazy"
                    />
                  </div>
                </Link>
              </div>
            )}
          </div>
          <div
            className="toggle-btn-sidebar d-block d-lg-none"
            onClick={handleClick}
          >
            <div className="btn-box h-100 wd-fit d-flex align-items-center justify-content-center">
              <BtnComponent
                type={"button"}
                icon={<FontAwesomeIcon icon={faBarsStaggered} />}
              />
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="main_nav99">
          <ul className="navbar-nav mx-auto ">
            <li className={`nav-item ${homeActive}`}>
              <Link className="nav-link" to="/" data-toggle="dropdown">
                {t("generalSections.navBar.home")}
              </Link>
            </li>
            <li className={`nav-item ${listsActive}`}>
              <Link
                className="nav-link"
                to="/listing-page"
                data-toggle="dropdown"
              >
                {t("generalSections.navBar.list")}
              </Link>
            </li>
            <li className={`nav-item ${installmentActive}`}>
              <Link className="nav-link" to="/installment">
                {t("generalSections.navBar.installment")}
              </Link>
            </li>
            <li className={`nav-item ${landsActive}`}>
              <Link className="nav-link" to="/lands">
                {t("generalSections.navBar.lands")}
              </Link>
            </li>
            <li className={`nav-item ${offersActive}`}>
              <Link className="nav-link" to="/offers">
                {t("generalSections.navBar.offers")}
              </Link>
            </li>
            <li className={`nav-item ${financeActive}`}>
              <Link className="nav-link" to="/finance">
                {t("generalSections.navBar.finance")}
              </Link>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle" data-toggle="dropdown">
                {t("generalSections.navBar.sale")}
              </span>
              <ul className="dropdown-menu animate fade-up">
                {categories_loading === false
                  ? categories?.map((item) => {
                      return (
                        <li
                          className="dropdown-item icon-arrow"
                          key={item?.id}
                          id={item?.id}
                          onClick={() => handleCategoryClick(item?.id, "sale")}
                        >
                          {lang === "ar"
                            ? item?.data_trans
                              ? item?.data_trans[0]?.title_ar
                              : null
                            : item?.data_trans
                            ? item?.data_trans[1]?.title_en
                            : null}
                        </li>
                      );
                    })
                  : null}
              </ul>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle" data-toggle="dropdown">
                {t("generalSections.navBar.rent")}
              </span>
              <ul className="dropdown-menu animate fade-up">
                {categories_loading === false
                  ? categories?.map((item) => {
                      return (
                        <li
                          className="dropdown-item icon-arrow"
                          key={item?.id}
                          id={item?.id}
                          onClick={() => handleCategoryClick(item?.id, "rent")}
                        >
                          {lang === "ar"
                            ? item?.data_trans
                              ? item?.data_trans[0]?.title_ar
                              : null
                            : item?.data_trans
                            ? item?.data_trans[1]?.title_en
                            : null}
                        </li>
                      );
                    })
                  : null}
              </ul>
            </li>
            <li className={`nav-item ${servicesActive}`}>
              <Link
                className="nav-link active"
                to="/services-category-page"
                data-toggle="dropdown"
              >
                {t("generalSections.navBar.services")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Sidebar />
    </nav>
  );
};

export default InternalNavbar;
