import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetData } from "../../../../Hooks/GetDataHook";
const initialState = {
    allProperties:[],
    error:null,
    loading:true
}
export const GetAllProperties = createAsyncThunk("allProperties",async(queryString,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetData('/properties')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)
const PropertiesSlice = createSlice({
    name:"propertiesSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetAllProperties.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetAllProperties.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.allProperties =action?.payload?.data
        })
        .addCase(GetAllProperties.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload?.data
            state.allProperties =action?.payload?.data
        })
        
    }
})
export const propertiesReducer = PropertiesSlice.reducer
export const propertiesActions = PropertiesSlice.actions