import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllDistricts } from "../../Redux/Redux-Toolkit/Slices/Districts/DistrictsSlice";
const GetAllDistrictsHook = () => {
  const [loading,setLoading] = useState(false);
  const [districts,setDistricts] = useState([]);
  const res = useSelector((state) => state.rootReducer.DistrictsReducer.allDistricts);
  const dispatch = useDispatch();

  const getData =async () => {
    setLoading(true);
    await dispatch(GetAllDistricts());
    setLoading(false);
  }
  useEffect(() => {
    const cachedDistricts = sessionStorage.getItem('districts');
    if (cachedDistricts) {
      setDistricts(JSON.parse(cachedDistricts));
    } else {
        getData();
    }
  },[]);

  useEffect(() => {
    try{
      if (loading === false) {
        if (res?.data?.data) {
          setDistricts(res.data.data);
          sessionStorage.setItem("districts",JSON.stringify(res.data.data))
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,districts];
};

export default GetAllDistrictsHook;
