import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetNewsDetails } from "../../Redux/Redux-Toolkit/Slices/News/NewsSlice";

const GetNewDetailsHook = (id) => {
  const [loading, setLoading] = useState(false);
  const [newDetails, setNewDetails] = useState([]);

  const res = useSelector((state) => state.rootReducer.newsReducer.newsDetails);

  const dispatch = useDispatch();

  const getData = async () => {
    setLoading(true);
    await dispatch(GetNewsDetails(id));
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    if (res?.status === 200) {
      setNewDetails(res?.data?.data);
    }
  }, [res]);

  return [loading, newDetails];
};

export default GetNewDetailsHook;
