import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllNews } from "../../Redux/Redux-Toolkit/Slices/News/NewsSlice";

const GetAllNewsHook = (id) => {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);
  const [newsCount, setNewsCount] = useState([]);
  const [meta, setMeta] = useState([]);
  const firstRender = useRef(true);
  const dispatch = useDispatch();
  const res = useSelector((state) => state.rootReducer.newsReducer.allNews);

  const getData = async (page) => {
    setLoading(true);
    await dispatch(GetAllNews(page));
    setLoading(false);
  };
  const onPressPaginate = (page) => {
    getData(page);
  };
  useEffect(() => {
    if (firstRender.current) {
      getData();
      firstRender.current = false;
    }
  }, []);

  useEffect(() => {
    try {
      if (loading === false) {
        if (res) {
          setNews(res?.data?.data?.data);
          setNewsCount(res?.data?.data?.data?.length);
          setMeta(res?.data?.data?.meta);
        }
      }
    } catch (e) {}
  }, [loading, res]);

  return [loading, news, newsCount, meta, onPressPaginate];
};

export default GetAllNewsHook;
