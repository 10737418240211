import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { usePostData } from "../../../../Hooks/PostDataHook";

const initialState = {
    publisherSignUp:[],
    error:null,
    loading:true
}
export const publisherSignUp = createAsyncThunk("publisherSignUp",async(formData,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await usePostData('/register/publisher',formData)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
const PublisherSignUpSlice = createSlice({
    name:"PublisherSignUpSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(publisherSignUp.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(publisherSignUp.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.publisherSignUp = action?.payload
        })
        .addCase(publisherSignUp.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.publisherSignUp = action?.payload
        })
    }
})
export const publisherSignUpReducer = PublisherSignUpSlice.reducer
export const publisherSignUpActions = PublisherSignUpSlice.actions