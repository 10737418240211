import React from "react";
import NotFound from "../Utility/NotFound/NotFound";
import CustomPagination from "../Utility/CustomPagination/CustomPagination";
import ServiceCardSkeleton from "../Services/ServiceCardSkeleton/ServiceCardSkeleton";
import JobCard from "./JobCard";
import GetAllJobsHook from "../../CustomHooks/Jobs/GetAllJobsHook";

const JobsListContent = () => {
  const [loading, jobs, jobsCount, meta, onPressPaginate] = GetAllJobsHook();
  const offersSkeleton = Array.from({ length: 12 }, (_, index) => index + 1);

  return (
    <div className="list-content min-h-100">
      <div className="row units-wrapper">
        {loading === false ? (
          jobsCount > 0 ? (
            jobs?.map((item) => (
              <div className="col-md-6 col-xl-4" key={item?.id}>
                <JobCard item={item} />
              </div>
            ))
          ) : (
            <NotFound />
          )
        ) : (
          offersSkeleton.map((_, i) => (
            <div className="col-md-6 col-xl-3" key={i}>
              <ServiceCardSkeleton />
            </div>
          ))
        )}
      </div>
      <div className="pagination-content d-flex align-items-center justify-content-center">
        <CustomPagination
          totalPages={meta?.last_page}
          currentPage={meta?.current_page}
          onChange={onPressPaginate}
        />
      </div>
    </div>
  );
};

export default JobsListContent;
