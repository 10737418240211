import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetServiceDetails } from "../../Redux/Redux-Toolkit/Slices/Services/ServicesSlice";
const GetServiceDetailsHook = (id) => {
  const [loading, setLoading] = useState(false);
  const [serviceDetails, setServiceDetails] = useState([]);
  const dispatch = useDispatch();
  const res = useSelector(
    (state) => state.rootReducer.servicesReducer.serviceDetails
  );

  const getData = async () => {
    setLoading(true);
    await dispatch(GetServiceDetails(id));
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    try {
      if (loading === false) {
        if (res) {
          setServiceDetails(res?.data?.data);
        }
      }
    } catch (e) {}
  }, [loading, res]);

  return [loading, serviceDetails];
};

export default GetServiceDetailsHook;
