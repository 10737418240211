import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { usePostData } from "../../../../Hooks/PostDataHook";

const initialState = {
    signUp:[],
    error:null,
    loading:true
}
export const signup = createAsyncThunk("signup",async(formData,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await usePostData('/register',formData)
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
})
const SignUpSlice = createSlice({
    name:"SignUpSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        // start sign up operations
        .addCase(signup.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(signup.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.signUp = action?.payload
        })
        .addCase(signup.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.signUp = action?.payload
        })
        // End sign up operations
    }
})
export const signUpReducer = SignUpSlice.reducer
export const signUpActions = SignUpSlice.actions