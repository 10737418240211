import React from "react";
import TopBar from "./TopBar/TopBar";
import InternalNavbar from "../../Utility/Navbar/InternalNavbar/InternalNavbar";
const InternalHeader = ({
  installment,
  homeActive,
  listsActive,
  newsActive,
  agencyActive,
  agentsActive,
  aboutUsActive,
  servicesActive,
  installmentActive,
  landsActive,
  offersActive,
  financeActive,
}) => {
  return (
    <header className="internal-header">
      <TopBar />
      <InternalNavbar
        installment={installment}
        homeActive={homeActive}
        listsActive={listsActive}
        newsActive={newsActive}
        agencyActive={agencyActive}
        agentsActive={agentsActive}
        aboutUsActive={aboutUsActive}
        servicesActive={servicesActive}
        installmentActive={installmentActive}
        landsActive={landsActive}
        offersActive={offersActive}
        financeActive={financeActive}
      />
    </header>
  );
};

export default InternalHeader;
