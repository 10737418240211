import  { useEffect } from 'react';

const ClearLocalStorageOnReload = () => {
  useEffect(() => {
    const clearLocalStorage = () => {
      // Clear all items from local storage
      localStorage.clear();
    };

    window.addEventListener('beforeunload', clearLocalStorage);

    return () => {
      window.removeEventListener('beforeunload', clearLocalStorage);
    };
  },[]);

  return null; // This component doesn't render anything to the DOM
};

export default ClearLocalStorageOnReload;