import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetDataToken } from "../../../../Hooks/GetDataHook";

const initialState = {
    userInfo:[],
    error:null,
    loading:true
}
export const GetUserInfo = createAsyncThunk("userInfo",async(params,thunkAPI)=>{
    const {rejectWithValue} = thunkAPI
    try{
        const res = await useGetDataToken('/profile/info_user')
        return res
    }
    catch(error){
        return rejectWithValue(error)
    }
}
)

const userInfoSlice = createSlice({
    name:"userInfoSlice",
    initialState,
    extraReducers:(builder)=>{
        builder
        .addCase(GetUserInfo.pending,(state,action)=>{
            state.loading = true
            state.error = null
        })
        .addCase(GetUserInfo.fulfilled,(state,action)=>{
            state.loading = false
            state.error = null
            state.userInfo =action?.payload
        })
        .addCase(GetUserInfo.rejected,(state,action)=>{
            state.loading = false
            state.error = action?.payload
            state.userInfo =action?.payload
        })
    }
})
export const userInfoReducer = userInfoSlice.reducer
export const userInfoActions = userInfoSlice.actions