import baseUrl from "../Api/baseUrl";
import Cookies from 'js-cookie'
// this is custom hook called useDeleteData like useEffect and other hooks
const useDeleteData = async(url)=>{
    // url for our url from api
    const config={
        headers:{"Authorization":`Bearer ${Cookies.get("user_token")}`}
    }
    if(Cookies.get("user_token")){
        const res = await baseUrl.delete(url,config)
        return res
    }else{return}
}

export default useDeleteData