import React from "react";
import "./UserDashboardNav.css";
import { Link } from "react-router-dom";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { changeActive } from "../../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice";
import logo from "../../../images/logo.jpg";

const UserDashboardNav = () => {
  // code for display home sidebar
  const dispatch = useDispatch();
  const handleClick = (e) => {
    dispatch(changeActive());
  };
  return (
    <nav className="user-dashboard-nav">
      <div className="dashboard-area">
        <div className="dashboard-nav">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="menu-wrapper d-flex align-content-center justify-content-center">
                  <div className="left-side d-flex align-items-center">
                    <Link to="/">
                      <div className="logo-content  d-flex align-items-center">
                        <img
                          className="logo w-100"
                          src={logo}
                          alt="logo"
                          loading="lazy"
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="right-side d-flex align-content-center justify-content-end">
                    <div
                      className="toggle-btn-sidebar d-block d-lg-none"
                      onClick={handleClick}
                    >
                      <div className="btn-box h-100 wd-fit d-flex align-items-center justify-content-center">
                        <button className="p-3 bg-white color-primary border-0">
                          <FontAwesomeIcon icon={faBarsStaggered} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default UserDashboardNav;
