import { Skeleton } from "@mui/material";
import React from "react";

const CardSkeleton = () => {
  return (
    <div className="card-skeleton card-item my-2">
      <div className="desktop d-none d-sm-block">
        <div className="card-skeleton-head">
          <Skeleton variant="rounded" width={315} height={220} />
        </div>
        <div className="card-skeleton-body">
          <Skeleton
            variant="rounded"
            width={50}
            height={10}
            sx={{ margin: "5px 0" }}
          />
          <Skeleton
            variant="rounded"
            width={300}
            height={20}
            sx={{ margin: "5px 0" }}
          />
          <Skeleton
            variant="rounded"
            width={300}
            height={20}
            sx={{ margin: "5px 0" }}
          />
          <div className="d-flex">
            <Skeleton
              variant="rounded"
              width={50}
              height={10}
              sx={{ margin: "5px" }}
            />
            <Skeleton
              variant="rounded"
              width={50}
              height={10}
              sx={{ margin: "5px" }}
            />
            <Skeleton
              variant="rounded"
              width={50}
              height={10}
              sx={{ margin: "5px" }}
            />
          </div>
          <div className="d-flex">
            <Skeleton
              variant="rounded"
              width={50}
              height={10}
              sx={{ margin: "5px" }}
            />
            <Skeleton
              variant="rounded"
              width={50}
              height={10}
              sx={{ margin: "5px" }}
            />
            <Skeleton
              variant="rounded"
              width={50}
              height={10}
              sx={{ margin: "5px" }}
            />
          </div>
          <div className="d-flex">
            <Skeleton
              variant="rounded"
              width={70}
              height={20}
              sx={{ margin: "5px" }}
            />
            <Skeleton
              variant="rounded"
              width={70}
              height={20}
              sx={{ margin: "5px" }}
            />
          </div>
          <div className="card-skeleton-footer d-flex align-items-center">
            <div className="d-flex">
              <Skeleton
                variant="rounded"
                width={40}
                height={40}
                sx={{ margin: "5px" }}
              />
              <Skeleton
                variant="rounded"
                width={40}
                height={40}
                sx={{ margin: "5px" }}
              />
              <Skeleton
                variant="rounded"
                width={40}
                height={40}
                sx={{ margin: "5px" }}
              />
            </div>
            <div className="d-flex">
              <Skeleton
                variant="rounded"
                width={50}
                height={10}
                sx={{ margin: "5px" }}
              />
              <Skeleton
                variant="rounded"
                width={50}
                height={10}
                sx={{ margin: "5px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mobile d-block d-sm-none">
        <div className="content d-flex">
          <div className="card-skeleton-head mx-2">
            <Skeleton variant="rounded" width={150} height={130} />
          </div>
          <div className="card-skeleton-body">
            <Skeleton
              variant="rounded"
              width={50}
              height={10}
              sx={{ margin: "5px 0" }}
            />
            <Skeleton
              variant="rounded"
              width={150}
              height={15}
              sx={{ margin: "5px 0" }}
            />
            <Skeleton
              variant="rounded"
              width={150}
              height={15}
              sx={{ margin: "5px 0" }}
            />
            <div className="d-flex">
              <Skeleton
                variant="rounded"
                width={30}
                height={10}
                sx={{ margin: "5px" }}
              />
              <Skeleton
                variant="rounded"
                width={30}
                height={10}
                sx={{ margin: "5px" }}
              />
              <Skeleton
                variant="rounded"
                width={30}
                height={10}
                sx={{ margin: "5px" }}
              />
            </div>
            <div className="d-flex">
              <Skeleton
                variant="rounded"
                width={30}
                height={10}
                sx={{ margin: "5px" }}
              />
              <Skeleton
                variant="rounded"
                width={30}
                height={10}
                sx={{ margin: "5px" }}
              />
              <Skeleton
                variant="rounded"
                width={30}
                height={10}
                sx={{ margin: "5px" }}
              />
            </div>
            <div className="card-skeleton-footer d-flex align-items-center">
              <div className="d-flex">
                <Skeleton
                  variant="rounded"
                  width={20}
                  height={20}
                  sx={{ margin: "5px" }}
                />
                <Skeleton
                  variant="rounded"
                  width={20}
                  height={20}
                  sx={{ margin: "5px" }}
                />
                <Skeleton
                  variant="rounded"
                  width={20}
                  height={20}
                  sx={{ margin: "5px" }}
                />
              </div>
              <div className="d-flex">
                <Skeleton
                  variant="rounded"
                  width={30}
                  height={10}
                  sx={{ margin: "5px" }}
                />
                <Skeleton
                  variant="rounded"
                  width={30}
                  height={10}
                  sx={{ margin: "5px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSkeleton;
